import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Empty,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Spin,
  Table,
} from "antd";
import styled from "styled-components";

import Top from "../../components/Top";
import {
  CREATE_QUESTION,
  DELETE_QUESTION,
  DELETE_QUESTION_TYPE,
  QUESTION_TYPES,
  UPDATE_QUESTION,
  UPDATE_QUESTION_TYPE,
} from "../../gqls/questions";
import { useUser } from "../../utils";
import LabeledValue from "../../components/LabeledValue";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

export const QuestionTypePage = () => {
  const { user } = useUser();

  const { data, loading, refetch } = useQuery(QUESTION_TYPES, {
    fetchPolicy: "network-only",
  });

  const navigate = useNavigate();

  const [createQuestion, { loading: creating }] = useMutation(CREATE_QUESTION, {
    onCompleted: () => {
      setCreateVisible(false);
      refetch();
      message.success("Вопрос добавлен");
    },
    onError: (err) => {
      console.log(err);
      message.error("Произошла ошибка, повторите еще раз");
    },
  });

  const [updateQuestionType, { loading: updatingQuestionType }] = useMutation(
    UPDATE_QUESTION_TYPE,
    {
      onCompleted: () => {
        setUpdateTypeVisible(false);
        refetch();
        message.success("Тема изменена");
      },
      onError: (err) => {
        console.log(err);
        message.error("Произошла ошибка, повторите еще раз");
      },
    }
  );

  const [deleteQuestionType, { loading: deleting }] = useMutation(
    DELETE_QUESTION_TYPE,
    {
      onCompleted: () => {
        navigate("/questions");
        message.success("Тема удалена");
      },
      onError: (err) => {
        console.log(err);
        message.error("Произошла ошибка, повторите еще раз");
      },
    }
  );

  const [createVisible, setCreateVisible] = useState(false);
  const [updateTypeVisible, setUpdateTypeVisible] = useState(false);

  const params = useParams();

  const questionTypes = data?.questions || [];
  const questionType = questionTypes.find((item) => item.id === params.id);

  const handleCreateQuestion = (values) => {
    createQuestion({
      variables: {
        data: {
          title: values.title,
          answer: values.answer,
          questionTypeId: params.id,
        },
      },
    });
  };

  const handleUpdateQuestionType = (values) => {
    updateQuestionType({
      variables: {
        data: { name: values.name },
        where: { id: params.id },
      },
    });
  };

  if (user?.role !== "moderator") return null;

  return (
    <>
      <Top title="Тема" />
      {loading ? (
        <Loading>
          <Spin />
        </Loading>
      ) : !questionType ? (
        <Empty description="Нет данных" />
      ) : (
        <>
          <LabeledValue label="Название" value={questionType.name} />
          <Actions style={{ marginBottom: 20 }}>
            <Button
              onClick={() => setUpdateTypeVisible(true)}
              type="primary"
              ghost
            >
              Изменить
            </Button>
            <Popconfirm
              title="Удалить?"
              onConfirm={() => {
                deleteQuestionType({ variables: { where: { id: params.id } } });
              }}
            >
              <Button
                loading={deleting}
                style={{ marginLeft: 10 }}
                danger
                ghost
              >
                Удалить
              </Button>
            </Popconfirm>
          </Actions>
          <Button
            onClick={() => setCreateVisible(true)}
            type="primary"
            ghost
            style={{ marginTop: 10 }}
          >
            Добавить вопрос
          </Button>
          <View>
            <Table
              style={{ marginTop: 10 }}
              dataSource={questionType.questions}
              rowKey={(row) => row.id}
              columns={[
                {
                  title: "Вопрос",
                  dataIndex: "title",
                  key: "title",
                },
                {
                  title: "Ответ",
                  dataIndex: "answer",
                  key: "answer",
                  className: "answer",
                },
                {
                  title: "",
                  render: (row) => (
                    <RowAction question={row} refetch={refetch} />
                  ),
                },
              ]}
            />
          </View>
          <Modal
            title="Добавить вопрос"
            destroyOnClose
            open={createVisible}
            onCancel={() => setCreateVisible(false)}
            footer={[
              <Button key="cancel" onClick={() => setCreateVisible(false)}>
                Закрыть
              </Button>,
              <Button
                key="submit"
                htmlType="submit"
                type="primary"
                form="create-question"
                loading={creating}
              >
                Добавить
              </Button>,
            ]}
          >
            <Form
              onFinish={handleCreateQuestion}
              layout="vertical"
              name="create-question"
            >
              <Form.Item
                name="title"
                label="Вопрос"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="answer"
                label="Ответ"
                rules={[{ required: true }]}
              >
                <Input.TextArea rows={6} />
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            title="Изменить тему"
            open={updateTypeVisible}
            onCancel={() => setUpdateTypeVisible(false)}
            destroyOnClose
            footer={[
              <Button key="cancel" onClick={() => setUpdateTypeVisible(false)}>
                Закрыть
              </Button>,
              <Button
                key="submit"
                htmlType="submit"
                type="primary"
                form="update-question-type"
                loading={updatingQuestionType}
              >
                Изменить
              </Button>,
            ]}
          >
            <Form
              initialValues={{ name: questionType.name }}
              name="update-question-type"
              layout="vertical"
              onFinish={handleUpdateQuestionType}
            >
              <Form.Item
                name="name"
                rules={[{ required: true }]}
                label="Название"
              >
                <Input />
              </Form.Item>
            </Form>
          </Modal>
        </>
      )}
    </>
  );
};

const RowAction = ({ question, refetch }) => {
  const [visibleEdit, setVisibleEdit] = useState(false);

  const [updateQuestion, { loading: updating }] = useMutation(UPDATE_QUESTION, {
    onCompleted: () => {
      refetch();
      setVisibleEdit(false);
      message.success("Вопрос изменен");
    },
    onError: (err) => {
      console.log(err);
      message.error("Произошла ошибка, повторите еще раз");
    },
  });

  const [deleteQuestion, { loading: deleting }] = useMutation(DELETE_QUESTION, {
    onCompleted: () => {
      refetch();
      message.success("Вопрос удален");
    },
    onError: (err) => {
      console.log(err);
      message.error("Произошла ошибка, повторите еще раз");
    },
  });

  const handleUpdateQuestion = (values) => {
    updateQuestion({
      variables: {
        data: {
          title: values.title,
          answer: values.answer,
        },
        where: { id: question.id },
      },
    });
  };

  return (
    <>
      <Actions>
        <Button
          onClick={() => setVisibleEdit(true)}
          icon={<EditOutlined />}
          type="primary"
          ghost
        />
        <Popconfirm
          title="Удалить?"
          onConfirm={() =>
            deleteQuestion({ variables: { where: { id: question.id } } })
          }
        >
          <Button
            icon={<DeleteOutlined />}
            danger
            ghost
            style={{ marginLeft: 10 }}
            loading={deleting}
          />
        </Popconfirm>
      </Actions>
      <Modal
        title="Изменить вопрос"
        destroyOnClose
        open={visibleEdit}
        onCancel={() => setVisibleEdit(false)}
        footer={[
          <Button key="cancel" onClick={() => setVisibleEdit(false)}>
            Закрыть
          </Button>,
          <Button
            key="submit"
            htmlType="submit"
            type="primary"
            form="edit-question"
            loading={updating}
          >
            Изменить
          </Button>,
        ]}
      >
        <Form
          onFinish={handleUpdateQuestion}
          layout="vertical"
          name="edit-question"
          initialValues={{
            title: question.title,
            answer: question.answer,
          }}
        >
          <Form.Item name="title" label="Вопрос" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="answer" label="Ответ" rules={[{ required: true }]}>
            <Input.TextArea rows={6} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

const Loading = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
`;

const View = styled.div`
  .answer {
    width: 70%;
    white-space: pre-wrap;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
