import { gql } from "@apollo/client";

export const ADVERTISINGS = gql`
  query ADVERTISINGS($data: AdvertisingsInput) {
    advertisings(data: $data) {
      id
      createdAt
      updatedAt
      name
      content
      image
    }
  }
`;

export const ADVERTISING = gql`
  query ADVERTISING($where: AdvertisingWhereInput!) {
    advertising(where: $where) {
      id
      createdAt
      updatedAt
      name
      content
      image
    }
  }
`;

export const CREATE_ADVERTISING = gql`
  mutation CREATE_ADVERTISING($data: CreateAdvertisingInput!) {
    createAdvertising(data: $data) {
      id
      createdAt
      updatedAt
      name
      content
      image
    }
  }
`;

export const UPDATE_ADVERTISING = gql`
  mutation UPDATE_ADVERTISING(
    $data: UpdateAdvertisingInput!
    $where: AdvertisingWhereInput!
  ) {
    updateAdvertising(data: $data, where: $where) {
      id
      createdAt
      updatedAt
      name
      content
      image
    }
  }
`;

export const DELETE_ADVERTISING = gql`
  mutation DELETE_ADVERTISING($where: AdvertisingWhereInput!) {
    deleteAdvertising(where: $where) {
      id
      createdAt
      updatedAt
      name
      content
      image
    }
  }
`;
