import { gql } from "@apollo/client";

export const SHOP_ADDRESS_POINTS = gql`
  query SHOP_ADDRESS_POINTS($where: AddressPointWhereInput!) {
    shopAddressPoints(where: $where) {
      id
      city
      address
      longitude
      latitude
      workingTime
      description
    }
  }
`;

export const CREATE_ADDRESS_POINT = gql`
  mutation CREATE_ADDRESS_POINT($data: AddressPointCreateInput!) {
    createAddressPoint(data: $data) {
      id
      city
      address
      longitude
      latitude
      workingTime
      description
    }
  }
`;

export const UPDATE_ADDRESS_POINT = gql`
  mutation UPDATE_ADDRESS_POINT(
    $where: AddressPointWhereUniqueInput!
    $data: AddressPointUpdateInput!
  ) {
    updateAddressPoint(where: $where, data: $data) {
      id
      city
      address
      longitude
      latitude
      workingTime
      description
    }
  }
`;

export const DELETE_ADDRESS_POINT = gql`
  mutation DELETE_ADDRESS_POINT($where: AddressPointWhereUniqueInput!) {
    deleteAddressPoint(where: $where) {
      id
      city
      address
      longitude
      latitude
      workingTime
      description
    }
  }
`;
