import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { Button, Empty, message, Popconfirm, Spin } from "antd";
import styled from "styled-components";
import moment from "moment";

import Top from "../../components/Top";
import LabeledValue from "../../components/LabeledValue";
import { useUser } from "../../utils";
import { SHOP_CATEGORY } from "../../gqls/shopCategory";
import { ShopCategoryProducts } from "./products";
import { DELETE_SHOP_CATEGORY } from "../../gqls/shopCategory";

export const ShopCategoryPage = () => {
  const params = useParams();

  const { user } = useUser();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const shopId = searchParams.get("shopId");

  const navigate = useNavigate();

  const { data, loading } = useQuery(SHOP_CATEGORY, {
    fetchPolicy: "network-only",
    variables: { where: { id: params.id, shopId } },
  });

  const [deleteShopCategory, { loading: deleting }] = useMutation(
    DELETE_SHOP_CATEGORY,
    {
      onCompleted: () => {
        message.success("Подкатегория удалена");
        navigate("/shop");
      },
      onError: (err) => {
        console.error(err);
        message.error("Произошла ошибка, повторите еще раз");
      },
    }
  );

  const shopCategory = data?.shopCategory;

  const handleDelete = () => {
    deleteShopCategory({
      variables: {
        where: { id: params.id, shopId },
      },
    });
  };

  if (!params.id) return null;

  return (
    <>
      <Top title="Подкатегория" />
      {loading ? (
        <Spin />
      ) : !shopCategory ? (
        <Empty description="Не найдено" />
      ) : (
        shopCategory && (
          <>
            {shopCategory.image && (
              <Logo
                src={`/uploads/${shopCategory.image}`}
                alt={shopCategory.image}
              />
            )}
            <LabeledValue label="Название" value={shopCategory.name} />
            <LabeledValue
              label="Дата создания"
              value={moment(shopCategory.createdAt).format("DD.MM.YYYY HH:mm")}
            />
            <View>
              <Link to={`/shopcategory/${params.id}/update?shopId=${shopId}`}>
                <Button type="primary" ghost>
                  Изменить
                </Button>
              </Link>

              <Popconfirm title="Удалить?" onConfirm={handleDelete}>
                <Button
                  loading={deleting}
                  style={{ marginLeft: 10 }}
                  type="primary"
                  ghost
                  danger
                >
                  Удалить
                </Button>
              </Popconfirm>
            </View>
            <ShopCategoryProducts shopCategory={shopCategory} shopId={shopId} />
          </>
        )
      )}
    </>
  );
};

const Logo = styled.img`
  height: 150px;
  border: 1px solid #d8d8d8;
  margin-bottom: 15px;
`;

const View = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
