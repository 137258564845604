import React from "react";
import { Button, Form, Input, message, Modal } from "antd";
import { useMutation } from "@apollo/client";
import { CREATE_SUBCATEGORY } from "../../gqls/category";

export const CreateSubcategoryModal = ({
  visible = false,
  onClose = () => {},
  refetchCategories = () => {},
  categoryId,
}) => {
  const [createSubcategory, { loading }] = useMutation(CREATE_SUBCATEGORY, {
    onCompleted: () => {
      refetchCategories();
      message.success("Подкатегория добавлена");
      onClose();
    },
    onError: (err) => {
      console.error(err);
      message.error("Произошла ошибка, повторите еще раз");
    },
  });

  const handleFinish = (values) => {
    createSubcategory({
      variables: { data: { name: values.name, categoryId } },
    });
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      title="Добавить подкатегорию"
      destroyOnClose
      footer={[
        <Button key="close" onClick={onClose}>
          Закрыть
        </Button>,
        <Button
          loading={loading}
          htmlType="submit"
          form="create-subcategory"
          type="primary"
          key="submit"
        >
          Добавить
        </Button>,
      ]}
    >
      <Form onFinish={handleFinish} layout="vertical" name="create-subcategory">
        <Form.Item
          label="Название подкатегории"
          name="name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
