import React from "react";
import { Button as AntButton } from "antd";
import styled from "styled-components";
import { Link } from "react-router-dom";

import LabeledValue from "../components/LabeledValue";
import Top from "../components/Top";
import { useUser } from "../utils";

const Button = styled(AntButton)`
  margin-top: 10px;
`;

export const ProfilePage = () => {
  const { user } = useUser();

  return (
    <>
      <Top title="Профиль" />
      <LabeledValue label="Email" value={user.email} />
      <LabeledValue label="Имя" value={user.name} />
      <LabeledValue
        label="Роль"
        value={user.role === "shop" ? "Магазин" : "Администратор"}
      />
      <Link to="/profile/change-password">
        <Button type="primary" ghost>
          Изменить пароль
        </Button>
      </Link>
    </>
  );
};
