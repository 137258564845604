export * from "./apollo-client";
export * from "./hooks";

export const colors = [
  "#FFFFFF",
  "#000000",
  "#EB5757",
  "#FFCDCF",
  "#219653",
  "#6FCF97",
  "#F2994A",
  "#F2C94C",
  "#2F80ED",
  "#56CCF2",
  "#9B51E0",
  "#828282",
  "#D9D9D9",
];

export const sizes = [
  "XS",
  "S",
  "M",
  "L",
  "XL",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "62",
  "68",
  "74",
  "80",
  "86",
  "92",
  "98",
  "104",
  "110",
  "116",
  "122",
  "128",
  "134",
  "140",
  "146",
];
