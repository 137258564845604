import React from "react";
import { Button, Checkbox, Form, Input, message, Modal } from "antd";
import { useMutation } from "@apollo/client";
import { UPDATE_SUBCATEGORY } from "../../gqls/category";

export const UpdateSubcategoryModal = ({
  visible = false,
  onClose = () => {},
  refetchCategories = () => {},
  subcategory,
}) => {
  const [updateSubcategory, { loading }] = useMutation(UPDATE_SUBCATEGORY, {
    fetchPolicy: "network-only",
    onCompleted: () => {
      refetchCategories();
      message.success("Подкатегория изменена");
      onClose();
    },
    onError: (err) => {
      console.error(err);
      message.error("Произошла ошибка, повторите еще раз");
    },
  });

  const handleFinish = (values) => {
    updateSubcategory({
      variables: {
        data: {
          name: values.name,
          blocked: values.blocked,
          order: values.order ? Number(values.order) : undefined,
        },
        where: { id: subcategory.id },
      },
    });
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      title="Изменить подкатегорию"
      destroyOnClose
      footer={[
        <Button key="close" onClick={onClose}>
          Закрыть
        </Button>,
        <Button
          loading={loading}
          htmlType="submit"
          form="update-subcategory"
          type="primary"
          key="submit"
        >
          Изменить
        </Button>,
      ]}
    >
      <Form
        initialValues={{
          name: subcategory.name,
          blocked: subcategory.blocked,
          order: subcategory.order,
        }}
        onFinish={handleFinish}
        layout="vertical"
        name="update-subcategory"
      >
        <Form.Item
          label="Название подкатегории"
          name="name"
          rules={[{ required: true }]}
          style={{ marginBottom: 15 }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Сортировка"
          name="order"
          extra="Чем выше число, тем выше стоит подкатегория"
          style={{ marginBottom: 10 }}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          name="blocked"
          valuePropName="checked"
          style={{ marginBottom: 0 }}
        >
          <Checkbox>Заблокирована</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};
