import React from "react";
import { Table, Tag } from "antd";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";

import Top from "../../components/Top";
import { CATEGORIES } from "../../gqls/category";

export const CategoriesPage = () => {
  const { data, loading } = useQuery(CATEGORIES, {
    fetchPolicy: "network-only",
  });

  const categories = data?.categories || [];

  return (
    <>
      <Top title="Категории" />
      <Table
        loading={loading}
        dataSource={categories}
        columns={[
          {
            title: "Название",
            dataIndex: "name",
            key: "name",
            render: (value, row) => (
              <Link to={`/categories/${row.id}`}>{value}</Link>
            ),
          },
          {
            title: "Статус",
            dataIndex: "blocked",
            key: "blocked",
            render: (value) =>
              value ? (
                <Tag color="red">Заблокирована</Tag>
              ) : (
                <Tag color="green">Доступна</Tag>
              ),
          },
        ]}
        rowKey={(row) => row.id}
      />
    </>
  );
};
