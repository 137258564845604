import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Form, Input, message, Upload } from "antd";
import styled from "styled-components";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";

import Top from "../../components/Top";
import { useUser } from "../../utils";
import { ADVERTISING, UPDATE_ADVERTISING } from "../../gqls/advertising";

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e.length > 0 ? [e[e.length - 1]] : [];
  }
  return e?.fileList && e.fileList.length > 0
    ? [e.fileList[e.fileList.length - 1]]
    : [];
};

export const UpdateAdvertisingPage = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const params = useParams();
  const [form] = Form.useForm();

  const { loading: advertisingLoading } = useQuery(ADVERTISING, {
    variables: { where: { id: params.id } },
    onCompleted: (data) => {
      if (data?.advertising) {
        form.setFieldsValue({
          name: data.advertising.name,
          content: data.advertising.content,
          image: [
            {
              status: "done",
              url: `/uploads/${data.advertising.image}`,
              name: data.advertising.image,
              response: { filename: data.advertising.image },
            },
          ],
        });
      }
    },
  });

  const [updateAdvertising, { loading }] = useMutation(UPDATE_ADVERTISING, {
    onCompleted: () => {
      message.success("Реклама успешно изменена");
      navigate("/advertisings/" + params.id);
    },
    onError: (err) => {
      console.error(err);
      message.error("Произошла ошибка, повторите еще раз");
    },
  });

  const handleFinish = (values) => {
    const image = values?.image?.[0]?.response?.filename;
    if (!image) {
      message.error("Выберите файл для загрузки");
      return;
    }
    updateAdvertising({
      variables: {
        data: {
          name: values.name,
          content: values.content,
          image,
        },
        where: { id: params.id },
      },
    });
  };

  if (user?.role !== "moderator") return null;

  return (
    <>
      <Top title="Изменить рекламу" />
      <Container>
        <Form
          form={form}
          name="create-advertising"
          onFinish={handleFinish}
          layout="vertical"
        >
          <Form.Item
            label="Название рекламы"
            name="name"
            rules={[{ required: true }]}
          >
            <Input disabled={advertisingLoading} />
          </Form.Item>
          <Form.Item
            label="Текст рекламы"
            name="content"
            rules={[{ required: true }]}
          >
            <Input disabled={advertisingLoading} />
          </Form.Item>
          <Form.Item
            name="image"
            label="Изображение"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[{ required: true }]}
          >
            <Upload
              name="file"
              action="/api/upload"
              listType="picture"
              accept="image/*"
              headers={{ authorization: localStorage.getItem("token") }}
              disabled={advertisingLoading}
            >
              <Button icon={<UploadOutlined />}>
                Нажмите, чтобы загрузить
              </Button>
            </Upload>
          </Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Изменить
          </Button>
        </Form>
      </Container>
    </>
  );
};

const Container = styled.div`
  max-width: 500px;
`;
