import React, { useState } from "react";
import styled from "styled-components";
import { useMutation, useQuery } from "@apollo/client";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Empty,
  Form,
  message,
  Modal,
  Select,
  Spin,
  Table,
  Tag,
} from "antd";
import moment from "moment";
import IMask from "imask";

import Top from "../../components/Top";
import LabeledValue from "../../components/LabeledValue";
import { ORDER, UPDATE_ORDER } from "../../gqls/order";
import { ORDER_PRODUCTS } from "../../gqls/product";
import { useUser } from "../../utils";

const moneyMask = IMask.createMask({
  mask: Number,
  thousandsSeparator: " ",
});

export const OrderPage = () => {
  const params = useParams();

  const { user } = useUser();

  const [updateStatusVisible, setUpdateStatusVisible] = useState(false);

  const isModerator = user?.role === "moderator";

  const [statusForm] = Form.useForm();

  const { data, loading } = useQuery(ORDER, {
    fetchPolicy: "network-only",
    variables: { where: { id: params.id } },
    onCompleted: (data) => {
      if (data?.order?.status) {
        statusForm.setFieldsValue({ status: data.order.status });
      }
    },
  });

  const order = data?.order;

  const { data: productsData, loading: productsLoading } = useQuery(
    ORDER_PRODUCTS,
    {
      variables: { where: { orderId: params.id } },
    }
  );

  const [updateOrder, { loading: updating }] = useMutation(UPDATE_ORDER, {
    onCompleted: () => {
      setUpdateStatusVisible(false);
      message.success("Статус изменен");
    },
    onError: (err) => {
      console.error(err);
      message.error("Произошла ошибка, повторите еще раз");
    },
  });

  const handleUpdateStatus = (values) => {
    if (values.status) {
      updateOrder({
        variables: {
          where: { id: params.id },
          data: { status: values.status },
        },
      });
    }
  };

  const renderAddress = () => {
    let address = `${order.deliveryInfo.city}, ${order.deliveryInfo.street}, ${order.deliveryInfo.number}`;
    if (order.deliveryInfo.entry) {
      address += `, ${order.deliveryInfo.entry} подъезд`;
    }
    if (order.deliveryInfo.floor) {
      address += `, ${order.deliveryInfo.floor} этаж`;
    }
    if (order.deliveryInfo.doorCode) {
      address += `, домофон ${order.deliveryInfo.doorCode}`;
    }
    return address;
  };

  const renderPickupPoint = () => {
    let pickupPoint = `${order.pickupPoint.city}, ${order.pickupPoint.address}`;
    return pickupPoint;
  };

  const renderStatus = () => {
    const status = order.status;
    if (status === "new") {
      return <Tag color="default">В обработке</Tag>;
    }
    if (status === "on-pickup") {
      return <Tag color="blue">Готов к выдаче</Tag>;
    }
    if (status === "on-delivery") {
      return <Tag color="blue">Ожидается доставка</Tag>;
    }
    if (status === "done") {
      return <Tag color="green">Выполнен</Tag>;
    }
    if (status === "cancelled") {
      return <Tag color="red">Отменен</Tag>;
    }
    return null;
  };

  let userName = [];

  if (order?.user?.profile?.surname) {
    userName.push(order.user.profile.surname);
  }
  if (order?.user?.profile?.name) {
    userName.push(order.user.profile.name);
  }
  if (order?.user?.profile?.middleName) {
    userName.push(order.user.profile.middleName);
  }

  let recipientName = [];

  if (order?.recipient?.surname) {
    recipientName.push(order.recipient.surname);
  }
  if (order?.recipient?.name) {
    recipientName.push(order.recipient.name);
  }
  if (order?.recipient?.middleName) {
    recipientName.push(order.recipient.middleName);
  }

  const productsItems = productsData?.orderProducts || [];
  const products = order?.products?.reduce((acc, current, index) => {
    const product = productsItems.find((item) => item.id === current.productId);
    if (!product) return acc;
    return [
      ...acc,
      {
        ...product,
        count: current.count || 0,
        key: `${product.id}-${index}`,
        size: current.size,
        color: current.color,
      },
    ];
  }, []);

  return (
    <>
      <Top title="Заказ" />
      {loading ? (
        <Loading>
          <Spin />
        </Loading>
      ) : !order ? (
        <Empty />
      ) : (
        <>
          <LabeledValue label="Номер заказа" value={order.number} />
          {isModerator ? (
            <LabeledValue label="Магазин" value={order.shop.name} />
          ) : null}
          <LabeledValue
            label="Тип доставки"
            value={order.deliveryType === "delivery" ? "Доставка" : "Самовывоз"}
          />
          {order.deliveryType === "delivery" ? (
            <>
              <LabeledValue label="Адрес доставки" value={renderAddress()} />
              <LabeledValue
                label="Комментарий для курьера"
                value={order.deliveryComment}
              />
            </>
          ) : (
            <LabeledValue label="Пункт выдачи" value={renderPickupPoint()} />
          )}
          <LabeledValue
            label="Способ оплаты"
            value={
              order.paymentType === "card"
                ? "Картой при получении"
                : "Наличными"
            }
          />
          <LabeledValue
            label="К оплате"
            value={moneyMask.resolve(String(order.totalPrice)) + " ₽"}
          />
          {order.paymentType === "cash" ? (
            <LabeledValue
              label="С какой суммы подготовить сдачу"
              value={
                order.cashAmount
                  ? moneyMask.resolve(String(order.cashAmount)) + " ₽"
                  : undefined
              }
            />
          ) : null}
          <LabeledValue label="Статус" value={renderStatus()} />
          <LabeledValue
            label="Дата создания"
            value={moment(order.createdAt).format("DD.MM.YYYY HH:mm")}
          />
          {isModerator ? null : (
            <Actions>
              <Button
                onClick={() => setUpdateStatusVisible(true)}
                type="primary"
                ghost
              >
                Изменить статус
              </Button>
            </Actions>
          )}
          <Users>
            <div className="item">
              <Top title="Пользователь" />
              <LabeledValue label="Номер телефона" value={order.user.phone} />
              <LabeledValue label="Email" value={order.user.email} />
              <LabeledValue label="Имя" value={userName.join(" ")} />
            </div>
            {order?.recipient ? (
              <div className="item">
                <Top title="Получатель" />
                <LabeledValue
                  label="Номер телефона"
                  value={order.recipient.phone}
                />
                <LabeledValue label="Email" value={order.recipient.email} />
                <LabeledValue
                  label="Имя"
                  value={
                    recipientName.length ? recipientName.join(" ") : undefined
                  }
                />
              </div>
            ) : null}
          </Users>
          <Top title="Товары" style={{ marginTop: 30 }} />
          <Table
            loading={productsLoading}
            dataSource={products}
            rowKey={(row) => row.id}
            scroll={{ x: 1000 }}
            columns={[
              {
                title: "",
                render: (product) => (
                  <Image
                    src={`/uploads/${product.images[0]}`}
                    alt={product.images[0]}
                  />
                ),
              },
              {
                title: "Название",
                dataIndex: "name",
                key: "name",
                render: (name, row) => (
                  <Link to={`/shop-products/${row.id}`}>{name}</Link>
                ),
              },
              {
                title: "Количество",
                render: (product) => (product.count || 0) + " шт.",
              },
              {
                title: "Цена",
                render: (row) => (
                  <div>
                    {moneyMask.resolve(String(row.price))} ₽
                    {row.fullPrice ? (
                      <FullPrice>
                        {moneyMask.resolve(String(row.fullPrice))} ₽
                      </FullPrice>
                    ) : null}
                  </div>
                ),
              },
              {
                title: "Цвет",
                render: (row) =>
                  row.color ? (
                    <div
                      style={{
                        backgroundColor: row.color,
                        height: 30,
                        width: 30,
                        borderRadius: 15,
                        border: "1px solid silver",
                      }}
                    />
                  ) : (
                    "-"
                  ),
              },
              {
                title: "Размер",
                render: (row) =>
                  row.size ? (
                    <div
                      style={{
                        height: 30,
                        width: 30,
                        borderRadius: 15,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "1px solid silver",
                        fontSize: 12,
                      }}
                    >
                      {row.size}
                    </div>
                  ) : (
                    "-"
                  ),
              },
              {
                title: "Категория",
                render: (row) =>
                  row.category.name +
                  (row.subcategory?.name ? `, ${row.subcategory?.name}` : ""),
              },
            ]}
          />
        </>
      )}
      <Modal
        title="Изменить статус"
        open={updateStatusVisible}
        onCancel={() => setUpdateStatusVisible(false)}
        footer={[
          <Button key="close" onClick={() => setUpdateStatusVisible(false)}>
            Закрыть
          </Button>,
          <Button
            key="submit"
            htmlType="submit"
            form="update-order-status"
            type="primary"
            loading={updating}
          >
            Изменить
          </Button>,
        ]}
      >
        <Form
          name="update-order-status"
          layout="vertical"
          onFinish={handleUpdateStatus}
          form={statusForm}
        >
          <Form.Item label="Статус" name="status" rules={[{ required: true }]}>
            <Select>
              <Select.Option value="new">В обработке</Select.Option>
              <Select.Option value="on-pickup">Готов к выдаче</Select.Option>
              <Select.Option value="on-delivery">
                Ожидается доставка
              </Select.Option>
              <Select.Option value="done">Выполнен</Select.Option>
              <Select.Option value="cancelled">Отменен</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

const Loading = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
`;

const FullPrice = styled.span`
  text-decoration: line-through;
  margin-left: 5px;
  font-size: 12px;
  color: gray;
`;

const Image = styled.img`
  width: 70px;
  height: 70px;
  object-fit: cover;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Users = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;

  .item {
    width: 50%;
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;

    .item {
      width: 100%;

      :last-child {
        margin-top: 20px;
      }
    }
  }
`;
