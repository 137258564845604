import React from "react";
import { Upload as AntUpload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";

export const Upload = ({
  accept = "image/*",
  multiple = false,
  maxCount,
  fileList = [],
  setFileList = () => {},
}) => {
  const uploadFile = async (file) => {
    setFileList((prev) =>
      prev.map((item) => {
        if (file.uid === item.uid) {
          item.status = "uploading";
        }
        return item;
      })
    );
    const formData = new FormData();
    formData.append("file", file);
    const token = localStorage.getItem("token");
    axios
      .request({
        url: "/api/upload",
        method: "POST",
        data: formData,
        headers: {
          authorization: token,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentage = Math.floor((loaded * 100) / total);
          setFileList((prev) =>
            prev.map((item) => {
              if (file.uid === item.uid) {
                item.percent = percentage;
              }
              return item;
            })
          );
        },
      })
      .then((response) => {
        setFileList((prev) =>
          prev.map((item) => {
            if (file.uid === item.uid) {
              item.status = "success";
              item.response = response.data;
              item.url = `/uploads/${response.data.filename}`;
            }
            return item;
          })
        );
      })
      .catch((err) => {
        console.error(err);
        setFileList((prev) =>
          prev.map((item) => {
            if (file.uid === item.uid) {
              item.status = "error";
            }
            return item;
          })
        );
      });
  };

  const handleBeforeUpload = (file) => {
    setFileList((prev) => [...prev, file]);
    uploadFile(file);
    return false;
  };

  const handleRemove = (file) => {
    setFileList((prev) => prev.filter((item) => item.uid !== file.uid));
  };

  return (
    <AntUpload
      listType="picture"
      accept={accept}
      multiple={multiple}
      maxCount={maxCount}
      fileList={fileList}
      beforeUpload={handleBeforeUpload}
      onRemove={handleRemove}
    >
      <Button icon={<UploadOutlined />}>Нажмите, чтобы загрузить</Button>
    </AntUpload>
  );
};
