import { useQuery } from "@apollo/client";
import React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Button, Empty, Spin, Tag } from "antd";
import styled from "styled-components";
import moment from "moment";
import IMask from "imask";

import Top from "../../components/Top";
import LabeledValue from "../../components/LabeledValue";
import { SHOP } from "../../gqls/shop";
import { useUser } from "../../utils";
import { SHOP_CATEGORIES } from "../../gqls/shopCategory";

const moneyMask = IMask.createMask({
  mask: Number,
  thousandsSeparator: " ",
});

export const ShopPage = () => {
  const params = useParams();

  const { user } = useUser();
  const navigate = useNavigate();

  const isModerator = user?.role === "moderator";
  const shopId = isModerator ? params.id : user.shop.id;

  const { data, loading } = useQuery(SHOP, {
    fetchPolicy: "network-only",
    variables: { where: { id: shopId } },
  });

  const { data: shopCategoryData, loading: shopCategoryLoader } = useQuery(
    SHOP_CATEGORIES,
    {
      fetchPolicy: "network-only",
      variables: {
        data: {
          shopId: shopId,
        },
      },
    }
  );

  const shopCategories = shopCategoryData?.shopCategories || [];
  const shop = data?.shop;

  return (
    <>
      <Top title="Магазин" />
      {loading ? (
        <Spin />
      ) : !shop ? (
        <Empty description="Не найдено" />
      ) : (
        shop && (
          <>
            {shop.logo && (
              <Logo src={`/uploads/${shop.logo}`} alt={shop.logo} />
            )}
            <LabeledValue label="Название" value={shop.name} />
            <LabeledValue label="Email" value={shop.email} />
            <LabeledValue label="Номер телефона" value={shop.phone} />
            <LabeledValue label="Описание" value={shop.description} />
            <LabeledValue
              label="Дата создания"
              value={moment(shop.createdAt).format("DD.MM.YYYY HH:mm")}
            />
            <LabeledValue
              label="Статус"
              value={
                shop.blocked ? (
                  <Tag color="red">Заблокирован</Tag>
                ) : (
                  <Tag color="green">Работает</Tag>
                )
              }
            />
            <LabeledValue
              label="Доставка"
              value={
                !shop?.deliveryExists ? (
                  <Tag color="orange">Нет доставки</Tag>
                ) : (
                  <Tag color="green">Есть доставка</Tag>
                )
              }
            />
            {shop.deliveryExists ? (
              <>
                <LabeledValue
                  label="Условие доставки"
                  value={shop.deliveryDeadline}
                />
                <LabeledValue
                  label="Минимальная сумма доставки"
                  value={
                    shop.deliveryMinAmount
                      ? moneyMask.resolve(String(shop.deliveryMinAmount)) + " ₽"
                      : undefined
                  }
                />
              </>
            ) : null}
            <View>
              <Link
                to={isModerator ? `/shops/${shopId}/update` : "/shop/update"}
              >
                <Button type="primary" ghost>
                  Изменить
                </Button>
              </Link>
            </View>
          </>
        )
      )}

      <Top
        title="Подкатегории"
        action={
          <Link to={`/shopcategory/create?shopId=${shopId}`}>
            <Button>Добавить</Button>
          </Link>
        }
        style={{ marginTop: 10 }}
      />
      {shopCategoryLoader ? (
        <Spin />
      ) : !shopCategories ? (
        <Empty description="Не найдено" />
      ) : (
        shopCategories && (
          <>
            {
              <ShopCategories>
                {shopCategories.map((item) => (
                  <ShopCategory
                    onClick={() =>
                      navigate(`/shopcategory/${item.id}?shopId=${shopId}`)
                    }
                    key={item.id}
                  >
                    <img src={`/uploads/${item.image}`} alt={item.image} />
                    <div className="text">{item.name}</div>
                  </ShopCategory>
                ))}
              </ShopCategories>
            }
          </>
        )
      )}
    </>
  );
};

const Logo = styled.img`
  height: 150px;
  border: 1px solid #d8d8d8;
  margin-bottom: 15px;
`;

const View = styled.div`
  margin-top: 15px;
`;

const ShopCategories = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ShopCategory = styled.div`
  margin-right: 15px;
  margin-bottom: 15px;

  img {
    width: 200px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
    cursor: pointer;
    transition: 0.3s all;

    &:hover {
      opacity: 0.8;
    }
  }

  .text {
    text-align: center;
    margin-top: 5px;
  }
`;
