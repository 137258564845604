import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Form, Input, Modal, Popconfirm, Table } from "antd";

import Top from "../../components/Top";
import { CITIES, CREATE_CITY, DELETE_CITY } from "../../gqls/city";

export const CitiesPage = () => {
  const { data, loading, refetch } = useQuery(CITIES, {
    fetchPolicy: "network-only",
  });

  const [createCityVisible, setCreateCityVisible] = useState(false);

  const [createCity, { loading: creating }] = useMutation(CREATE_CITY, {
    onCompleted: () => {
      setCreateCityVisible(false);
      refetch();
    },
  });

  const handleFinish = (values) => {
    createCity({ variables: { data: { name: values.name } } });
  };

  const cities = data?.cities || [];

  return (
    <>
      <Top
        title="Города"
        action={
          <Button onClick={() => setCreateCityVisible(true)}>
            Добавить город
          </Button>
        }
      />
      <Table
        loading={loading}
        dataSource={cities}
        rowKey={(row) => row.id}
        columns={[
          {
            title: "Название",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "",
            render: (row) => <DeleteCity city={row} refetch={refetch} />,
          },
        ]}
      />
      <Modal
        open={createCityVisible}
        onCancel={() => setCreateCityVisible(false)}
        title="Добавить город"
        destroyOnClose
        footer={[
          <Button key="close" onClick={() => setCreateCityVisible(false)}>
            Закрыть
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            form="create-city"
            loading={creating}
          >
            Добавить
          </Button>,
        ]}
      >
        <Form onFinish={handleFinish} name="create-city" layout="vertical">
          <Form.Item name="name" label="Название" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

const DeleteCity = ({ city, refetch }) => {
  const [deleteCity, { loading: deleting }] = useMutation(DELETE_CITY, {
    onCompleted: () => {
      refetch();
    },
  });

  return (
    <Popconfirm
      title="Удалить?"
      onConfirm={() => {
        deleteCity({ variables: { where: { id: city.id } } });
      }}
    >
      <Button size="small" loading={deleting} danger ghost>
        Удалить
      </Button>
    </Popconfirm>
  );
};
