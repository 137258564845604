import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Empty, message, Popconfirm, Spin } from "antd";
import styled from "styled-components";
import moment from "moment";

import Top from "../../components/Top";
import LabeledValue from "../../components/LabeledValue";
import { useUser } from "../../utils";
import { DELETE_PROMOTION, PROMOTION } from "../../gqls/promotion";
import { PromotionProducts } from "./products";

export const PromotionPage = () => {
  const params = useParams();

  const { user } = useUser();

  const navigate = useNavigate();

  const { data, loading } = useQuery(PROMOTION, {
    fetchPolicy: "network-only",
    variables: { where: { id: params.id } },
  });

  const [deletePromotion, { loading: deleting }] = useMutation(
    DELETE_PROMOTION,
    {
      onCompleted: () => {
        message.success("Акция удалена");
        navigate("/promotions");
      },
      onError: (err) => {
        console.error(err);
        message.error("Произошла ошибка, повторите еще раз");
      },
    }
  );

  const promotion = data?.promotion;

  const handleDelete = () => {
    deletePromotion({
      variables: {
        where: { id: params.id },
      },
    });
  };

  if (!params.id) return null;
  if (user?.role !== "moderator") return null;

  return (
    <>
      <Top title="Акция" />
      {loading ? (
        <Spin />
      ) : !promotion ? (
        <Empty description="Не найдено" />
      ) : (
        promotion && (
          <>
            {promotion.image && (
              <Logo src={`/uploads/${promotion.image}`} alt={promotion.image} />
            )}
            <LabeledValue label="Название" value={promotion.name} />
            <LabeledValue
              label="Дата создания"
              value={moment(promotion.createdAt).format("DD.MM.YYYY HH:mm")}
            />
            <View>
              <Link to={`/promotions/${params.id}/update`}>
                <Button type="primary" ghost>
                  Изменить
                </Button>
              </Link>
              <Popconfirm title="Удалить?" onConfirm={handleDelete}>
                <Button
                  loading={deleting}
                  style={{ marginLeft: 10 }}
                  type="primary"
                  ghost
                  danger
                >
                  Удалить
                </Button>
              </Popconfirm>
            </View>
            <PromotionProducts promotion={promotion} />
          </>
        )
      )}
    </>
  );
};

const Logo = styled.img`
  height: 150px;
  border: 1px solid #d8d8d8;
  margin-bottom: 15px;
`;

const View = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
