import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { ConfigProvider } from "antd";
import ru from "antd/locale/ru_RU";

import GlobalStyles from "./components/GlobalStyles";
import { apolloClient } from "./utils";
import { ProfilePage } from "./pages/profile";
import { Layout } from "./components/Layout";
import { LoginPage } from "./pages/login";
import { ShopsPage } from "./pages/shops";
import { CreateShopPage } from "./pages/shops/create";
import { ShopPage } from "./pages/shops/shop";
import { ChangePasswordPage } from "./pages/change-password";
import { UpdateShopPage } from "./pages/shops/update";
import { PromotionsPage } from "./pages/promotions";
import { CreatePromotionPage } from "./pages/promotions/create";
import { PromotionPage } from "./pages/promotions/promotion";
import { UpdatePromotionPage } from "./pages/promotions/update";
import { CategoriesPage } from "./pages/categories";
import { CategoryPage } from "./pages/categories/category";
import { ShopProductsPage } from "./pages/shop-products";
import { CreateShopProductPage } from "./pages/shop-products/create";
import { ShopProductPage } from "./pages/shop-products/product";
import { UpdateShopProductPage } from "./pages/shop-products/update";
import { CitiesPage } from "./pages/cities";
import { AddressPointsPage } from "./pages/address-points";
import { CreateAddressPointPage } from "./pages/address-points/create";
import { UpdateAddressPointPage } from "./pages/address-points/update";
import { OrdersPage } from "./pages/orders";
import { OrderPage } from "./pages/orders/order";
import { ReviewsPage } from "./pages/reviews";
import { ContactUsPage } from "./pages/contact-us";
import { QuestionsPage } from "./pages/questions";
import { CreateQuestionTypePage } from "./pages/questions/create";
import { QuestionTypePage } from "./pages/questions/question-type";
import { CreateShopProductExcelPage } from "./pages/shop-products/excel";
import { PromocodePage } from "./pages/promocode";
import { CreateShopCategoryPage } from "./pages/shop-category/create";
import { ShopCategoryPage } from "./pages/shop-category/shop-category";
import { UpdateShopCategoryPage } from "./pages/shop-category/update";
import { PopupsPage } from "./pages/popup";
import { CreatePopupPage } from "./pages/popup/create";
import { PopupPage } from "./pages/popup/popup";
import { UpdatePopupPage } from "./pages/popup/update";
import { MetricsPage } from "./pages/metrics";
import { CreateAdvertisingPage } from "./pages/advertising/create";
import { AdvertisingsPage } from "./pages/advertising";
import { AdvertisingPage } from "./pages/advertising/advertising";
import { UpdateAdvertisingPage } from "./pages/advertising/update";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout>
        <ProfilePage />
      </Layout>
    ),
  },
  {
    path: "/shops",
    element: (
      <Layout>
        <ShopsPage />
      </Layout>
    ),
  },
  {
    path: "/shops/create",
    element: (
      <Layout>
        <CreateShopPage />
      </Layout>
    ),
  },
  {
    path: "/shops/:id",
    element: (
      <Layout>
        <ShopPage />
      </Layout>
    ),
  },
  {
    path: "/shops/:id/update",
    element: (
      <Layout>
        <UpdateShopPage />
      </Layout>
    ),
  },
  {
    path: "/profile/change-password",
    element: (
      <Layout>
        <ChangePasswordPage />
      </Layout>
    ),
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/promotions",
    element: (
      <Layout>
        <PromotionsPage />
      </Layout>
    ),
  },
  {
    path: "/promotions/create",
    element: (
      <Layout>
        <CreatePromotionPage />
      </Layout>
    ),
  },
  {
    path: "/promotions/:id",
    element: (
      <Layout>
        <PromotionPage />
      </Layout>
    ),
  },
  {
    path: "/promotions/:id/update",
    element: (
      <Layout>
        <UpdatePromotionPage />
      </Layout>
    ),
  },
  {
    path: "/categories",
    element: (
      <Layout>
        <CategoriesPage />
      </Layout>
    ),
  },
  {
    path: "/categories/:id",
    element: (
      <Layout>
        <CategoryPage />
      </Layout>
    ),
  },
  {
    path: "/shop",
    element: (
      <Layout>
        <ShopPage />
      </Layout>
    ),
  },
  {
    path: "/shop/update",
    element: (
      <Layout>
        <UpdateShopPage />
      </Layout>
    ),
  },
  {
    path: "/shop-products",
    element: (
      <Layout>
        <ShopProductsPage />
      </Layout>
    ),
  },
  {
    path: "/shop-products/excel",
    element: (
      <Layout>
        <CreateShopProductExcelPage />
      </Layout>
    ),
  },
  {
    path: "/shop-products/create",
    element: (
      <Layout>
        <CreateShopProductPage />
      </Layout>
    ),
  },
  {
    path: "/shop-products/:id",
    element: (
      <Layout>
        <ShopProductPage />
      </Layout>
    ),
  },
  {
    path: "/shop-products/:id/update",
    element: (
      <Layout>
        <UpdateShopProductPage />
      </Layout>
    ),
  },
  {
    path: "/shopcategory/create",
    element: (
      <Layout>
        <CreateShopCategoryPage />
      </Layout>
    ),
  },
  {
    path: "/shopcategory/:id",
    element: (
      <Layout>
        <ShopCategoryPage />
      </Layout>
    ),
  },
  {
    path: "/shopcategory/:id/update",
    element: (
      <Layout>
        <UpdateShopCategoryPage />
      </Layout>
    ),
  },
  {
    path: "/cities",
    element: (
      <Layout>
        <CitiesPage />
      </Layout>
    ),
  },
  {
    path: "/address-points",
    element: (
      <Layout>
        <AddressPointsPage />
      </Layout>
    ),
  },
  {
    path: "/address-points/create",
    element: (
      <Layout>
        <CreateAddressPointPage />
      </Layout>
    ),
  },
  {
    path: "/address-points/:id/update",
    element: (
      <Layout>
        <UpdateAddressPointPage />
      </Layout>
    ),
  },
  {
    path: "/orders",
    element: (
      <Layout>
        <OrdersPage />
      </Layout>
    ),
  },
  {
    path: "/orders/:id",
    element: (
      <Layout>
        <OrderPage />
      </Layout>
    ),
  },
  {
    path: "/reviews",
    element: (
      <Layout>
        <ReviewsPage />
      </Layout>
    ),
  },
  {
    path: "/contact-us",
    element: (
      <Layout>
        <ContactUsPage />
      </Layout>
    ),
  },
  {
    path: "/questions",
    element: (
      <Layout>
        <QuestionsPage />
      </Layout>
    ),
  },
  {
    path: "/questions/create",
    element: (
      <Layout>
        <CreateQuestionTypePage />
      </Layout>
    ),
  },
  {
    path: "/questions/:id",
    element: (
      <Layout>
        <QuestionTypePage />
      </Layout>
    ),
  },
  {
    path: "/promocode",
    element: (
      <Layout>
        <PromocodePage />
      </Layout>
    ),
  },
  {
    path: "/popup",
    element: (
      <Layout>
        <PopupsPage />
      </Layout>
    ),
  },
  {
    path: "/popup/create",
    element: (
      <Layout>
        <CreatePopupPage />
      </Layout>
    ),
  },
  {
    path: "/popup/:id",
    element: (
      <Layout>
        <PopupPage />
      </Layout>
    ),
  },
  {
    path: "/popup/:id/update",
    element: (
      <Layout>
        <UpdatePopupPage />
      </Layout>
    ),
  },
  {
    path: "/metrics",
    element: (
      <Layout>
        <MetricsPage />
      </Layout>
    ),
  },
  {
    path: "/advertisings",
    element: (
      <Layout>
        <AdvertisingsPage />
      </Layout>
    ),
  },
  {
    path: "/advertising/create",
    element: (
      <Layout>
        <CreateAdvertisingPage />
      </Layout>
    ),
  },
  {
    path: "/advertisings/:id",
    element: (
      <Layout>
        <AdvertisingPage />
      </Layout>
    ),
  },
  {
    path: "/advertisings/:id/update",
    element: (
      <Layout>
        <UpdateAdvertisingPage />
      </Layout>
    ),
  },
]);

const App = () => {
  return (
    <ConfigProvider locale={ru}>
      <ApolloProvider client={apolloClient}>
        <RouterProvider router={router} />
        <GlobalStyles />
      </ApolloProvider>
    </ConfigProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<App />);
