import { gql } from "@apollo/client";

const REVIEW_RETURN = gql`
  fragment ReviewReturn on Review {
    id
    createdAt
    updatedAt
    text
    rating
    images
    shopComment
    user {
      id
      email
      phone
      profile {
        id
        surname
        name
        middleName
      }
    }
    shop {
      id
      name
      logo
    }
    product {
      id
      name
      images
      price
      fullPrice
      description
    }
  }
`;

export const REVIEWS = gql`
  ${REVIEW_RETURN}
  query REVIEWS($where: ReviewsWhereInput) {
    reviews(where: $where) {
      reviews {
        ...ReviewReturn
      }
      count
    }
  }
`;

export const LEAVE_COMMENT_REVIEW = gql`
  ${REVIEW_RETURN}
  mutation LEAVE_COMMENT_REVIEW(
    $where: ReviewWhereInput!
    $data: LeaveCommentReviewInput!
  ) {
    leaveCommentReview(where: $where, data: $data) {
      ...ReviewReturn
    }
  }
`;
