import { gql } from "@apollo/client";

const ORDER_FRAGMENT = gql`
  fragment OrderReturn on Order {
    id
    createdAt
    updatedAt
    shop {
      id
      name
      logo
    }
    user {
      id
      phone
      email
      profile {
        id
        surname
        name
        middleName
      }
    }
    deliveryType
    pickupPoint {
      city
      address
      longitude
      latitude
      workingTime
      description
    }
    deliveryInfo {
      city
      street
      number
      entry
      floor
      doorCode
    }
    products {
      productId
      count
      size
      color
    }
    deliveryComment
    paymentType
    status
    totalPrice
    number
    cashAmount
    recipient {
      surname
      name
      middleName
      email
      phone
    }
  }
`;

export const ORDERS = gql`
  ${ORDER_FRAGMENT}
  query ORDERS($where: OrdersWhereInput) {
    orders(where: $where) {
      orders {
        ...OrderReturn
      }
      count
    }
  }
`;

export const ORDER = gql`
  ${ORDER_FRAGMENT}
  query ORDER($where: OrderWhereInput!) {
    order(where: $where) {
      ...OrderReturn
    }
  }
`;

export const UPDATE_ORDER = gql`
  ${ORDER_FRAGMENT}
  mutation UPDATE_ORDER($where: OrderWhereInput!, $data: UpdateOrderInput!) {
    updateOrder(where: $where, data: $data) {
      ...OrderReturn
    }
  }
`;
