import React, { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Progress, Upload } from "antd";
import styled from "styled-components";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import Top from "../../components/Top";

export const CreateShopProductExcelPage = () => {
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPercent, setLoadingPercent] = useState(0);

  const navigate = useNavigate();

  const handleClick = () => {
    if (fileList.length) {
      const file = fileList[0].originFileObj;
      const formData = new FormData();
      formData.append("excel", file);
      const accessToken = localStorage.getItem("token");
      setLoading(true);
      axios
        .request({
          url: "/api/excel",
          method: "POST",
          data: formData,
          headers: {
            authorization: accessToken,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percentage = Math.floor((loaded * 100) / total);
            setLoadingPercent(percentage);
          },
        })
        .then(() => {
          message.success("Файл успешно загружен, товары добавлены");
          navigate("/shop-products");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Container>
      <Top
        title="Добавить товары через Excel файл"
        action={
          <a
            target="_blank"
            href="https://docs.google.com/spreadsheets/d/1gWCvAur7T27SXr0Xj71VMLSf1ihuSju8PcPfThBHuyM/edit?usp=sharing"
            rel="noreferrer"
          >
            Открыть пример файла
          </a>
        }
      />
      <Upload
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        beforeUpload={() => false}
        fileList={fileList}
        onChange={(e) => {
          if (e.fileList[e.fileList.length - 1]) {
            setFileList([e.fileList[e.fileList.length - 1]]);
          }
        }}
        style={{ maxWidth: 500 }}
      >
        <Button icon={<UploadOutlined />}>Нажмите чтобы загрузить</Button>
      </Upload>
      {loading ? (
        <div>
          <Progress percent={loadingPercent} />
        </div>
      ) : null}
      <Button
        style={{ marginTop: 20 }}
        type="primary"
        disabled={!fileList.length}
        onClick={handleClick}
        loading={loading}
      >
        Загрузить
      </Button>
      <p style={{ color: "gray", fontSize: 12 }}>
        Товары будут добавлены, но нужно будет загрузить фотографии и
        дополнительную информацию
      </p>
    </Container>
  );
};

const Container = styled.div`
  .ant-upload-list-item-container {
    max-width: 500px;
  }

  .ant-progress {
    max-width: 500px;
  }
`;
