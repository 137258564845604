import { gql } from "@apollo/client";

export const USER_MESSAGES = gql`
  query USER_MESSAGES {
    userMessages {
      id
      createdAt
      name
      email
      subject
      text
    }
  }
`;
