import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Form, Input, message, Upload } from "antd";
import styled from "styled-components";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";

import Top from "../../components/Top";
import { useUser } from "../../utils";
import { GET_POPUP, UPDATE_POPUP } from "../../gqls/popup";

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e.length > 0 ? [e[e.length - 1]] : [];
  }
  return e?.fileList && e.fileList.length > 0
    ? [e.fileList[e.fileList.length - 1]]
    : [];
};

export const UpdatePopupPage = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const params = useParams();
  const [form] = Form.useForm();

  const { loading: popupLoading } = useQuery(GET_POPUP, {
    variables: { where: { id: params.id } },
    onCompleted: (data) => {
      if (data?.getPopup) {
        form.setFieldsValue({
          title: data.getPopup.title,
          text: data.getPopup.content,
          image: [
            {
              status: "done",
              url: `/uploads/${data.getPopup.image_url}`,
              name: data.getPopup.image_url,
              response: { filename: data.getPopup.image_url },
            },
          ],
        });
      }
    },
  });

  const [updatePopup, { loading }] = useMutation(UPDATE_POPUP, {
    onCompleted: () => {
      message.success("Попап-окно успешно изменено");
      navigate("/popup/" + params.id);
    },
    onError: (err) => {
      console.error(err);
      message.error("Произошла ошибка, повторите еще раз");
    },
  });

  const handleFinish = (values) => {
    const image = values?.image?.[0]?.response?.filename;
    if (!image) {
      message.error("Выберите файл для загрузки");
      return;
    }
    updatePopup({
      variables: {
        id: params.id,
        data: {
          title: values.title,
          content: values.text,
          image_url: image,
          shown: false,
        },
      },
    });
  };

  if (user?.role !== "moderator") return null;

  return (
    <>
      <Top title="Изменить попап-окно" />
      <Container>
        <Form
          form={form}
          name="create-popup"
          onFinish={handleFinish}
          layout="vertical"
        >
          <Form.Item
            label="Название попап-окна"
            name="title"
            rules={[{ required: true }]}
          >
            <Input disabled={popupLoading} />
          </Form.Item>
          <Form.Item
            label="Текст попап-окна"
            name="text"
            rules={[{ required: true }]}
          >
            <Input disabled={popupLoading} />
          </Form.Item>
          <Form.Item
            name="image"
            label="Изображение"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[{ required: true }]}
          >
            <Upload
              name="file"
              action="/api/upload"
              listType="picture"
              accept="image/*"
              headers={{ authorization: localStorage.getItem("token") }}
              disabled={popupLoading}
            >
              <Button icon={<UploadOutlined />}>
                Нажмите, чтобы загрузить
              </Button>
            </Upload>
          </Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Изменить
          </Button>
        </Form>
      </Container>
    </>
  );
};

const Container = styled.div`
  max-width: 500px;
`;
