import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Empty, message, Popconfirm, Spin } from "antd";
import styled from "styled-components";
import moment from "moment";

import Top from "../../components/Top";
import LabeledValue from "../../components/LabeledValue";
import { useUser } from "../../utils";
import { DELETE_POPUP, GET_POPUP } from "../../gqls/popup";

export const PopupPage = () => {
  const params = useParams();
  const { user } = useUser();
  const navigate = useNavigate();

  const { data, loading } = useQuery(GET_POPUP, {
    fetchPolicy: "network-only",
    variables: { where: { id: params.id } },
  });

  const [deletePopup, { loading: deleting }] = useMutation(DELETE_POPUP, {
    onCompleted: () => {
      message.success("Попап-окно удалено");
      navigate("/popup");
    },
    onError: (err) => {
      console.error(err);
      message.error("Произошла ошибка, повторите еще раз");
    },
  });

  const popup = data?.getPopup;

  const handleDelete = () => {
    deletePopup({
      variables: {
        id: params.id,
      },
    });
  };

  if (!params.id) return null;
  if (user?.role !== "moderator") return null;

  return (
    <>
      <Top title="Попап-окно" />
      {loading ? (
        <Spin />
      ) : !popup ? (
        <Empty description="Не найдено" />
      ) : (
        popup && (
          <>
            {popup.image_url && (
              <Logo src={`/uploads/${popup.image_url}`} alt={popup.image_url} />
            )}
            <LabeledValue label="Название" value={popup.title} />
            <LabeledValue label="Текст" value={popup.content} />
            <LabeledValue
              label="Дата создания"
              value={moment(popup.createdAt).format("DD.MM.YYYY HH:mm")}
            />
            <View>
              <Link to={`/popup/${params.id}/update`}>
                <Button type="primary" ghost>
                  Изменить
                </Button>
              </Link>
              <Popconfirm title="Удалить?" onConfirm={handleDelete}>
                <Button
                  loading={deleting}
                  style={{ marginLeft: 10 }}
                  type="primary"
                  ghost
                  danger
                >
                  Удалить
                </Button>
              </Popconfirm>
            </View>
          </>
        )
      )}
    </>
  );
};

const Logo = styled.img`
  height: 150px;
  border: 1px solid #d8d8d8;
  margin-bottom: 15px;
`;

const View = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
