import React from "react";
import { useMutation } from "@apollo/client";
import { Button, Form, Input, message, Upload } from "antd";
import styled from "styled-components";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import Top from "../../components/Top";
import { useUser } from "../../utils";
import { CREATE_SHOP } from "../../gqls/shop";

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e.length > 0 ? [e[e.length - 1]] : [];
  }
  return e?.fileList && e.fileList.length > 0
    ? [e.fileList[e.fileList.length - 1]]
    : [];
};

export const CreateShopPage = () => {
  const { user } = useUser();

  const navigate = useNavigate();

  const [createShop, { loading }] = useMutation(CREATE_SHOP, {
    onCompleted: () => {
      message.success("Магазин успешно добавлен");
      navigate("/shops");
    },
    onError: (err) => {
      console.error(err);
      if (err.message === "moderator-exist") {
        message.error("Модератор с таким Email уже существует");
      } else {
        message.error("Произошла ошибка, повторите еще раз");
      }
    },
  });

  const handleFinish = (values) => {
    const logo = values?.logo?.[0]?.response?.filename;
    if (!logo) {
      message.error("Выберите файл для загрузки");
      return;
    }
    createShop({
      variables: {
        data: {
          name: values.name,
          phone: values.phone,
          email: values.email,
          description: values.description,
          logo,
        },
      },
    });
  };

  if (user?.role !== "moderator") return null;

  return (
    <>
      <Top title="Добавить магазин" />
      <Container>
        <Form name="create-shop" onFinish={handleFinish} layout="vertical">
          <Form.Item
            label="Название магазина"
            name="name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Описание"
            name="description"
            rules={[{ required: true }]}
          >
            <Input.TextArea rows={6} />
          </Form.Item>
          <Form.Item
            name="logo"
            label="Логотип"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[{ required: true }]}
          >
            <Upload
              name="file"
              action="/api/upload"
              listType="picture"
              accept="image/*"
              headers={{ authorization: localStorage.getItem("token") }}
            >
              <Button icon={<UploadOutlined />}>
                Нажмите, чтобы загрузить
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item label="Номер телефона" name="phone">
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            extra="По этому электронному адресу будет создан аккаунт магазина"
            rules={[{ required: true }, { type: "email" }]}
          >
            <Input />
          </Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Добавить
          </Button>
        </Form>
      </Container>
    </>
  );
};

const Container = styled.div`
  max-width: 500px;
`;
