import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Checkbox, Form, Input, message, Upload } from "antd";
import styled from "styled-components";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";

import Top from "../../components/Top";
import { useUser } from "../../utils";
import { SHOP, UPDATE_SHOP } from "../../gqls/shop";

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e.length > 0 ? [e[e.length - 1]] : [];
  }
  return e?.fileList && e.fileList.length > 0
    ? [e.fileList[e.fileList.length - 1]]
    : [];
};

export const UpdateShopPage = () => {
  const { user } = useUser();
  const params = useParams();

  const isModerator = user?.role === "moderator";
  const shopId = isModerator ? params.id : user.shop.id;

  const [deliveryExists, setDeliveryExists] = useState(false);

  const [form] = Form.useForm();

  const navigate = useNavigate();

  const { loading: shopLoading } = useQuery(SHOP, {
    variables: { where: { id: shopId } },
    onCompleted: (data) => {
      if (data?.shop) {
        if (data.shop.deliveryExists) {
          setDeliveryExists(true);
        }
        form.setFieldsValue({
          name: data.shop.name,
          description: data.shop.description,
          phone: data.shop.phone,
          email: data.shop.email,
          blocked: data.shop.blocked,
          priority: data.shop.priority,
          deliveryExists: data.shop.deliveryExists,
          deliveryDeadline: data.shop.deliveryDeadline,
          deliveryMinAmount: data.shop.deliveryMinAmount,
          logo: [
            {
              status: "done",
              url: `/uploads/${data.shop.logo}`,
              name: data.shop.logo,
              response: {
                filename: data.shop.logo,
              },
            },
          ],
        });
      }
    },
  });

  const [updateShop, { loading }] = useMutation(UPDATE_SHOP, {
    onCompleted: () => {
      message.success("Магазин успешно изменен");
      if (isModerator) {
        navigate("/shops/" + shopId);
      } else {
        navigate("/shop");
      }
    },
    onError: (err) => {
      console.error(err);
      message.error("Произошла ошибка, повторите еще раз");
    },
  });

  const handleFinish = (values) => {
    const logo = values?.logo?.[0]?.response?.filename;
    if (!logo) {
      message.error("Выберите файл для загрузки");
      return;
    }
    updateShop({
      variables: {
        data: {
          name: values.name,
          phone: values.phone,
          email: values.email,
          description: values.description,
          logo,
          blocked: values.blocked,
          priority: values.priority,
          deliveryExists: values.deliveryExists ? true : false,
          deliveryDeadline: values.deliveryExists
            ? values.deliveryDeadline
            : undefined,
          deliveryMinAmount:
            values.deliveryExists && values.deliveryMinAmount
              ? parseFloat(values.deliveryMinAmount)
              : undefined,
        },
        where: { id: shopId },
      },
    });
  };

  return (
    <>
      <Top title="Изменить магазин" />
      <Container>
        <Form
          form={form}
          name="create-shop"
          onFinish={handleFinish}
          layout="vertical"
        >
          <Form.Item
            label="Название магазина"
            name="name"
            rules={[{ required: true }]}
          >
            <Input disabled={shopLoading} />
          </Form.Item>
          <Form.Item
            label="Описание"
            name="description"
            rules={[{ required: true }]}
          >
            <Input.TextArea disabled={shopLoading} rows={6} />
          </Form.Item>
          <Form.Item
            name="logo"
            label="Логотип"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[{ required: true }]}
          >
            <Upload
              name="file"
              action="/api/upload"
              listType="picture"
              accept="image/*"
              headers={{ authorization: localStorage.getItem("token") }}
              disabled={shopLoading}
            >
              <Button icon={<UploadOutlined />}>
                Нажмите, чтобы загрузить
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item label="Номер телефона" name="phone">
            <Input type="number" disabled={shopLoading} />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true }, { type: "email" }]}
          >
            <Input disabled={shopLoading} />
          </Form.Item>
          {isModerator && (
            <Form.Item name="blocked" valuePropName="checked">
              <Checkbox>Заблокирован</Checkbox>
            </Form.Item>
          )}
          {isModerator && (
            <Form.Item name="priority" valuePropName="checked">
              <Checkbox>Приоритетный магазин</Checkbox>
            </Form.Item>
          )}
          {!isModerator ? (
            <>
              <Form.Item
                style={{ marginBottom: 15 }}
                name="deliveryExists"
                valuePropName="checked"
              >
                <Checkbox onChange={(e) => setDeliveryExists(e.target.checked)}>
                  Есть доставка
                </Checkbox>
              </Form.Item>
              {deliveryExists ? (
                <>
                  <Form.Item label="Условия доставки" name="deliveryDeadline">
                    <Input.TextArea rows={3} disabled={shopLoading} />
                  </Form.Item>
                  <Form.Item
                    label="Минимальная сумма доставки (₽)"
                    name="deliveryMinAmount"
                  >
                    <Input type="number" disabled={shopLoading} />
                  </Form.Item>
                </>
              ) : null}
            </>
          ) : null}
          <Button loading={loading} type="primary" htmlType="submit">
            Изменить
          </Button>
        </Form>
      </Container>
    </>
  );
};

const Container = styled.div`
  max-width: 500px;
`;
