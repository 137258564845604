import React from "react";
import { useQuery } from "@apollo/client";
import { Button, Empty, Spin } from "antd";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";

import Top from "../../components/Top";
import { PROMOTIONS } from "../../gqls/promotion";

export const PromotionsPage = () => {
  const { data, loading } = useQuery(PROMOTIONS, {
    fetchPolicy: "network-only",
  });

  const navigate = useNavigate();

  const promotions = data?.promotions || [];

  return (
    <>
      <Top
        title="Акции"
        action={
          <Link to="/promotions/create">
            <Button>Добавить</Button>
          </Link>
        }
      />
      {loading ? (
        <Loading>
          <Spin />
        </Loading>
      ) : null}
      {!loading && promotions.length === 0 ? (
        <Empty description="Нет акций" />
      ) : null}
      {promotions.length ? (
        <Promotions>
          {promotions.map((item) => (
            <Promotion
              onClick={() => navigate(`/promotions/${item.id}`)}
              key={item.id}
            >
              <img src={`/uploads/${item.image}`} alt={item.image} />
              <div className="text">{item.name}</div>
            </Promotion>
          ))}
        </Promotions>
      ) : null}
    </>
  );
};

const Loading = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
`;

const Promotions = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Promotion = styled.div`
  margin-right: 15px;
  margin-bottom: 15px;

  img {
    width: 200px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
    cursor: pointer;
    transition: 0.3s all;

    &:hover {
      opacity: 0.8;
    }
  }

  .text {
    text-align: center;
    margin-top: 5px;
  }
`;
