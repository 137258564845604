import React from "react";
import { Button, Form, Input, message } from "antd";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import Top from "../../components/Top";
import { useUser } from "../../utils";
import { CREATE_QUESTION_TYPE } from "../../gqls/questions";

export const CreateQuestionTypePage = () => {
  const { user } = useUser();

  const navigate = useNavigate();

  const [createQuestionType, { loading }] = useMutation(CREATE_QUESTION_TYPE, {
    onCompleted: () => {
      navigate("/questions");
    },
    onError: (err) => {
      console.log(err);
      message.error("Произошла ошибка, повторите еще раз");
    },
  });

  const handleFinish = (values) => {
    createQuestionType({ variables: { data: { name: values.name } } });
  };

  if (user?.role !== "moderator") return null;

  return (
    <>
      <Top title="Добавить тему" />
      <Form
        onFinish={handleFinish}
        layout="vertical"
        name="create-question-type"
        style={{ maxWidth: 400 }}
      >
        <Form.Item label="Название" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Button loading={loading} type="primary" htmlType="submit">
          Добавить
        </Button>
      </Form>
    </>
  );
};
