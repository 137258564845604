import { gql } from "@apollo/client";

export const MODERATOR = gql`
  query MODERATOR {
    moderator {
      id
      email
      name
      role
      shop {
        id
      }
    }
  }
`;

export const LOGIN_MODERATOR = gql`
  mutation LOGIN_MODERATOR($data: LoginModeratorInput!) {
    loginModerator(data: $data) {
      accessToken
      moderator {
        id
        email
        name
        role
        shop {
          id
        }
      }
    }
  }
`;

export const CHANGE_PASSWORD_MODERATOR = gql`
  mutation CHANGE_PASSWORD_MODERATOR($data: ChangePasswordModerator!) {
    changePasswordModerator(data: $data) {
      id
      email
      name
      role
      shop {
        id
      }
    }
  }
`;
