import { gql } from "@apollo/client";

export const CITIES = gql`
  query CITIES {
    cities {
      id
      name
    }
  }
`;

export const CREATE_CITY = gql`
  mutation CREATE_CITY($data: CreateCityInput!) {
    createCity(data: $data) {
      id
      name
    }
  }
`;

export const DELETE_CITY = gql`
  mutation DELETE_CITY($where: CityWhereInput!) {
    deleteCity(where: $where) {
      id
      name
    }
  }
`;
