import React from "react";
import { useQuery } from "@apollo/client";
import { Table } from "antd";

import Top from "../components/Top";
import { USER_MESSAGES } from "../gqls/user-message";
import { useUser } from "../utils";

export const ContactUsPage = () => {
  const { user } = useUser();

  const { data, loading } = useQuery(USER_MESSAGES);

  if (user?.role !== "moderator") return null;

  const userMessages = data?.userMessages || [];

  return (
    <>
      <Top title="Обратная связь" />
      <Table
        loading={loading}
        dataSource={userMessages}
        rowKey={(row) => row.id}
        columns={[
          {
            title: "Имя",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "Email",
            dataIndex: "email",
            key: "email",
          },
          {
            title: "Тема",
            dataIndex: "subject",
            key: "subject",
          },
          {
            title: "Текст сообщения",
            dataIndex: "text",
            key: "text",
          },
        ]}
      />
    </>
  );
};
