import React from "react";
import { Button, Form, Input, Upload, message } from "antd";
import styled from "styled-components";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { CREATE_SHOP_CATEGORY } from "../../gqls/shopCategory";

import Top from "../../components/Top";
import { useUser } from "../../utils";

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e.length > 0 ? [e[e.length - 1]] : [];
  }
  return e?.fileList && e.fileList.length > 0
    ? [e.fileList[e.fileList.length - 1]]
    : [];
};

export const CreateShopCategoryPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const shopId = searchParams.get("shopId");

  const [createShopCategory, { loading }] = useMutation(CREATE_SHOP_CATEGORY, {
    onCompleted: () => {
      message.success("Подкатегория успешно добавлена");
      navigate("/shop");
    },
    onError: (err) => {
      console.error(err);
      message.error("Произошла ошибка, повторите еще раз");
    },
  });

  const handleFinish = (values) => {
    const image = values?.image?.[0]?.response?.filename;
    if (!image) {
      message.error("Выберите файл для загрузки");
      return;
    }
    createShopCategory({
      variables: {
        data: {
          name: values.name,
          image,
          shopId,
        },
      },
    });
  };

  return (
    <>
      <Top title="Добавить подкатегорию магазина" />
      <Container>
        <Form
          name="create-shop-category"
          onFinish={handleFinish}
          layout="vertical"
        >
          <Form.Item
            label="Название подкатегории магазина"
            name="name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="image"
            label="Изображение"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[{ required: true }]}
            extra="Размер баннера 1080px (ширина) на 600px (высота)"
          >
            <Upload
              name="file"
              action="/api/upload"
              listType="picture"
              accept="image/*"
              headers={{ authorization: localStorage.getItem("token") }}
            >
              <Button icon={<UploadOutlined />}>
                Нажмите, чтобы загрузить
              </Button>
            </Upload>
          </Form.Item>
          <Button type="primary" loading={loading} htmlType="submit">
            Добавить
          </Button>
        </Form>
      </Container>
    </>
  );
};

const Container = styled.div`
  max-width: 500px;
`;
