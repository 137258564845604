import { gql } from "@apollo/client";

const PROMOCODE_FRAGMENT = gql`
  fragment PromocodeReturn on Promocode {
    id
    code
    discount
    expiration_date
    is_active
    usage_limit
    used_count
  }
`;

export const PROMOCODE = gql`
  query PROMOCODE($where: PromocodeInput!) {
    promocode(where: $where) {
      id
      code
      discount
      expiration_date
      is_active
      usage_limit
      used_count
    }
  }
`;

export const CREATE_PROMOCODE = gql`
  mutation CREATE_PROMOCODE($data: CreatePromocodeInput!) {
    createPromocode(data: $data) {
      ...PromocodeReturn
    }
  }
  ${PROMOCODE_FRAGMENT}
`;
