import React, { useState } from "react";
import styled from "styled-components";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Table } from "antd";
import { Link } from "react-router-dom";

import Top from "../../components/Top";
import { PRODUCTS } from "../../gqls/product";
import { AddProductModal } from "./add-product-modal";
import { REMOVE_PRODUCT_FROM_SHOP_CATEGORY } from "../../gqls/shopCategory";

export const ShopCategoryProducts = ({ shopCategory, shopId }) => {
  const [addProductVisible, setAddProductVisible] = useState(false);

  const { data, loading, refetch } = useQuery(PRODUCTS, {
    fetchPolicy: "network-only",
    variables: {
      where: {
        shopCategoryId: shopCategory.id,
      },
    },
  });

  const products = data?.products?.products || [];

  return (
    <>
      <Top
        style={{ marginTop: 25 }}
        title="Товары акции"
        action={
          <Button onClick={() => setAddProductVisible(true)}>
            Добавить товар
          </Button>
        }
      />
      <Table
        dataSource={products}
        loading={loading}
        rowKey={(row) => row.id}
        columns={[
          {
            title: "",
            render: (row) => (
              <ProductImg
                src={`/uploads/${row.images[0]}`}
                alt={row.images[0]}
              />
            ),
          },
          {
            title: "Название",
            dataIndex: "name",
            key: "name",
            render: (name, row) => (
              <Link to={`/shop-products/${row.id}`}>{name}</Link>
            ),
          },
          {
            title: "Цена",
            render: (row) => (
              <div>
                {row.price} ₽
                {row.fullPrice ? (
                  <FullPrice>{row.fullPrice} ₽</FullPrice>
                ) : null}
              </div>
            ),
          },
          {
            title: "Категория",
            render: (row) =>
              row.category.name +
              (row.subcategory?.name ? `, ${row.subcategory?.name}` : ""),
          },
          {
            title: "Магазин",
            render: (row) => (
              <Link to={`/shops/${row.shop.id}`}>{row.shop.name}</Link>
            ),
          },
          {
            title: "",
            render: (row) => (
              <RemoveFromShopCategory
                shopCategory={shopCategory}
                product={row}
                refetchProducts={refetch}
                shopId={shopId}
              />
            ),
          },
        ]}
      />
      <AddProductModal
        visible={addProductVisible}
        onClose={() => setAddProductVisible(false)}
        shopCategory={shopCategory}
        refetchProducts={refetch}
        shopId={shopId}
      />
    </>
  );
};

// TODO: Delete Not Working
const RemoveFromShopCategory = ({
  product,
  shopCategory,
  refetchProducts,
  shopId,
}) => {
  const [removeProductFromShopCategory, { loading: removing }] = useMutation(
    REMOVE_PRODUCT_FROM_SHOP_CATEGORY,
    {
      onCompleted: () => {
        refetchProducts();
      },
    }
  );

  const handleRemove = () => {
    removeProductFromShopCategory({
      variables: {
        where: { id: shopCategory.id, shopId },
        data: { productId: product.id },
      },
    });
  };

  return (
    <Button onClick={handleRemove} danger ghost size="small" loading={removing}>
      Убрать
    </Button>
  );
};

const FullPrice = styled.span`
  text-decoration: line-through;
  margin-left: 5px;
  font-size: 12px;
  color: gray;
`;

const ProductImg = styled.img`
  width: 70px;
  height: 70px;
  object-fit: cover;
`;
