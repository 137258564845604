import React from "react";

import { useQuery } from "@apollo/client";
import { METRICS } from "../../gqls/metrics";
import { useUser } from "../../utils";
import { Bar } from "react-chartjs-2";
import styled from "styled-components";

import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";

import Top from "../../components/Top";

export const MetricsPage = () => {
  const { user } = useUser();
  Chart.register(CategoryScale);

  const { data } = useQuery(METRICS, {
    variables: { shopId: user?.shop?.id },
  });

  const metrics = data?.metrics || [];

  const totalVisitors = metrics.reduce(
    (total, metric) => total + metric.visitors,
    0
  );

  console.log(metrics);

  const chartOptions = {
    scales: {
      x: {
        type: "category",
        position: "bottom",
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  const chartData = {
    labels: ["Сколько посетитетелй зашло в магазин"],
    datasets: [
      {
        label: "Сколько посетитетелй зашло в магазин",
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(75,192,192,0.6)",
        hoverBorderColor: "rgba(75,192,192,1)",
        data: [totalVisitors],
      },
    ],
  };

  return (
    <Container>
      <Top title="Метрика" />
      <Bar data={chartData} options={chartOptions} />
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
`;
