import React from "react";
import { useQuery } from "@apollo/client";
import { Link, useParams } from "react-router-dom";
import { Button, Empty, Spin, Tag } from "antd";
import styled from "styled-components";
import moment from "moment";

import Top from "../../components/Top";
import { useUser } from "../../utils";
import { PRODUCT } from "../../gqls/product";
import LabeledValue from "../../components/LabeledValue";

export const ShopProductPage = () => {
  const { user } = useUser();

  const params = useParams();

  const { data, loading } = useQuery(PRODUCT, {
    variables: { where: { id: params.id } },
    fetchPolicy: "network-only",
  });

  const product = data?.product;

  return (
    <>
      <Top title="Товар" />
      {loading ? (
        <Loading>
          <Spin />
        </Loading>
      ) : !product ? (
        <Empty description="Не найдено" />
      ) : (
        <>
          <Images>
            {product.images.map((item) => (
              <img key={item} src={`/uploads/${item}`} alt={item} />
            ))}
          </Images>
          <LabeledValue label="Название" value={product.name} />
          <LabeledValue
            label="Цена"
            value={
              <div>
                {product.price} ₽
                {product.fullPrice ? (
                  <FullPrice>{product.fullPrice} ₽</FullPrice>
                ) : null}
              </div>
            }
          />
          <LabeledValue label="Категория" value={product.category.name} />
          <LabeledValue
            label="Подкатегория"
            value={product.subcategory?.name}
          />
          <LabeledValue
            label="Yakutia"
            value={
              product.isTypeYakutia ? (
                <Tag color="cyan">Yakutia</Tag>
              ) : undefined
            }
          />
          <LabeledValue
            label="Статус"
            value={
              product.blocked ? (
                <Tag color="red">Заблокирован</Tag>
              ) : (
                <Tag color="green">Доступен</Tag>
              )
            }
          />
          <LabeledValue
            label="Дата создания"
            value={moment(product.createdAt).format("DD.MM.YYYY HH:mm")}
          />
          <LabeledValue
            label="Цвета"
            value={
              product.colors?.length ? (
                <Offers>
                  {product.colors.sort().map((item) => (
                    <Offer key={item} color={item} />
                  ))}
                </Offers>
              ) : (
                "-"
              )
            }
          />
          <LabeledValue
            label="Размеры"
            value={
              product.sizes?.length ? (
                <Offers>
                  {product.sizes.sort().map((item) => (
                    <Offer key={item}>{item}</Offer>
                  ))}
                </Offers>
              ) : (
                "-"
              )
            }
          />
          <LabeledValue
            label="Описание"
            value={product.description}
            hasShowMore
          />
          {user?.shop?.id ? (
            <Link to={`/shop-products/${params.id}/update`}>
              <Button type="primary" ghost>
                Изменить
              </Button>
            </Link>
          ) : null}
        </>
      )}
    </>
  );
};

const Loading = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
`;

const FullPrice = styled.span`
  text-decoration: line-through;
  margin-left: 5px;
  font-size: 12px;
  color: gray;
`;

const Images = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  img {
    height: 150px;
    border: 1px solid #d8d8d8;
    margin-right: 15px;
    margin-bottom: 15px;
  }
`;

const Offer = styled.div`
  width: 30px;
  height: 30px;
  border: 1px solid silver;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.color};
`;

const Offers = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
