import { gql } from "@apollo/client";

export const SHOP_CATEGORIES = gql`
  query SHOP_CATEGORIES($data: ShopCategoriesInput!) {
    shopCategories(data: $data) {
      id
      createdAt
      updatedAt
      name
      image
      productsIds
    }
  }
`;

export const SHOP_CATEGORY = gql`
  query SHOP_CATEGORY($where: ShopCategoryWhereInput!) {
    shopCategory(where: $where) {
      id
      createdAt
      updatedAt
      name
      image
      productsIds
    }
  }
`;

export const CREATE_SHOP_CATEGORY = gql`
  mutation CREATE_SHOP_CATEGORY($data: CreateShopCategoryInput!) {
    createShopCategory(data: $data) {
      id
      createdAt
      updatedAt
      name
      image
      productsIds
    }
  }
`;

export const UPDATE_SHOP_CATEGORY = gql`
  mutation UPDATE_SHOP_CATEGORY(
    $data: UpdateShopCategoryInput!
    $where: ShopCategoryWhereInput!
  ) {
    updateShopCategory(data: $data, where: $where) {
      id
      createdAt
      updatedAt
      name
      image
      productsIds
    }
  }
`;

export const DELETE_SHOP_CATEGORY = gql`
  mutation DELETE_SHOP_CATEGORY($where: ShopCategoryWhereInput!) {
    deleteShopCategory(where: $where) {
      id
      createdAt
      updatedAt
      name
      image
      productsIds
    }
  }
`;

export const ADD_PRODUCT_TO_SHOP_CATEGORY = gql`
  mutation ADD_PRODUCT_TO_SHOP_CATEGORY(
    $where: ShopCategoryWhereInput!
    $data: ProductToShopCategoryInput!
  ) {
    addProductToShopCategory(where: $where, data: $data) {
      id
      createdAt
      updatedAt
      name
      image
      productsIds
    }
  }
`;

export const REMOVE_PRODUCT_FROM_SHOP_CATEGORY = gql`
  mutation REMOVE_PRODUCT_FROM_SHOP_CATEGORY(
    $where: ShopCategoryWhereInput!
    $data: ProductToShopCategoryInput!
  ) {
    removeProductFromShopCategory(where: $where, data: $data) {
      id
      createdAt
      updatedAt
      name
      image
      productsIds
    }
  }
`;
