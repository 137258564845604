import React from "react";
import { Button, Form, Input, message, Select } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";

import Top from "../../components/Top";
import { useUser } from "../../utils";
import {
  SHOP_ADDRESS_POINTS,
  UPDATE_ADDRESS_POINT,
} from "../../gqls/address-point";
import { CITIES } from "../../gqls/city";

export const UpdateAddressPointPage = () => {
  const { user } = useUser();

  const params = useParams();

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const { loading: disabled } = useQuery(SHOP_ADDRESS_POINTS, {
    fetchPolicy: "network-only",
    variables: {
      where: { shopId: user?.shop?.id },
    },
    onCompleted: (data) => {
      if (Array.isArray(data?.shopAddressPoints)) {
        const addressPoint = data.shopAddressPoints.find(
          (item) => item.id === params?.id
        );
        if (addressPoint) {
          form.setFieldsValue({
            city: addressPoint.city,
            address: addressPoint.address,
            workingTime: addressPoint.workingTime,
            description: addressPoint.description,
          });
        }
      }
    },
  });

  const { data: citiesData } = useQuery(CITIES);

  const [updateAddressPoint, { loading }] = useMutation(UPDATE_ADDRESS_POINT, {
    onCompleted: () => {
      message.success("Пункт выдачи изменен");
      navigate("/address-points");
    },
    onError: (err) => {
      console.error(err);
      message.error("Произошла ошибка, попробуйте еще раз");
    },
  });

  const handleFinish = (values) => {
    updateAddressPoint({
      variables: { data: values, where: { id: params?.id } },
    });
  };

  if (!user?.shop?.id || !params?.id) return null;

  const cities = citiesData?.cities || [];

  return (
    <>
      <Top title="Изменить пункт выдачи" />
      <Form
        layout="vertical"
        name="update-address-point"
        style={{ maxWidth: 500 }}
        onFinish={handleFinish}
        disabled={disabled}
        form={form}
      >
        <Form.Item name="city" label="Город">
          <Select>
            {cities.map((item) => (
              <Select.Option key={item.id} value={item.name}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="address" label="Адрес">
          <Input />
        </Form.Item>
        <Form.Item name="workingTime" label="Рабочее время">
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Описание">
          <Input.TextArea rows={4} />
        </Form.Item>
        <Button loading={loading} type="primary" htmlType="submit">
          Изменить
        </Button>
      </Form>
    </>
  );
};
