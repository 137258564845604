import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Checkbox, Image, Input, Modal, Rate, Table } from "antd";
import styled from "styled-components";

import Top from "../../components/Top";
import { LEAVE_COMMENT_REVIEW, REVIEWS } from "../../gqls/review";
import { useUser } from "../../utils";
import { Link } from "react-router-dom";
import { CheckOutlined, PlusOutlined } from "@ant-design/icons";

export const ReviewsPage = () => {
  const { user } = useUser();

  const [filterWithText, setFilterWithText] = useState(false);

  const { data, loading, refetch } = useQuery(REVIEWS, {
    variables: {
      where: {
        shopId: user.shop.id,
        hasText: filterWithText ? true : undefined,
      },
    },
  });

  const reviews = data?.reviews?.reviews || [];
  const count = data?.reviews?.count || 0;

  return (
    <>
      <Top title={`Отзывы (${count})`} />
      <Filters>
        <Checkbox
          onChange={() => setFilterWithText((prev) => !prev)}
          checked={filterWithText}
          className="checkbox"
        >
          С ответом
        </Checkbox>
      </Filters>
      <Table
        loading={loading}
        dataSource={reviews}
        pagination={{ total: count }}
        rowKey={(row) => row.id}
        expandable={{
          expandedRowRender: (row) =>
            row.images.length ? (
              <Images>
                {row.images.map((image) => (
                  <Image src={`/uploads/${image}`} width={150} />
                ))}
              </Images>
            ) : (
              <div>Нет изображений</div>
            ),
        }}
        columns={[
          {
            title: "Рейтинг",
            render: (row) => (
              <Rating>
                <div className="rating">{row.rating}</div>
                <Rate disabled value={row.rating} />
              </Rating>
            ),
          },
          {
            title: "Комментарий",
            render: (row) => <Comment>{row.text}</Comment>,
          },
          {
            title: "Товар",
            render: (row) => (
              <Link to={`/shop-products/${row.product.id}`}>
                {row.product.name}
              </Link>
            ),
          },
          {
            title: "Пользователь",
            render: (row) => {
              let name = [];
              if (row?.user?.profile?.surname) {
                name.push(row.user.profile.surname);
              }
              if (row?.user?.profile?.name) {
                name.push(row.user.profile.name);
              }
              return name ? name.join(" ") : "";
            },
          },
          {
            title: "",
            render: (row) => <AddComment review={row} refetch={refetch} />,
          },
        ]}
      />
    </>
  );
};

const AddComment = ({ review, refetch }) => {
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState(review.shopComment || "");

  const [leaveCommentReview, { loading }] = useMutation(LEAVE_COMMENT_REVIEW, {
    onCompleted: () => {
      setVisible(false);
      refetch();
    },
  });

  const handleLeaveComment = () => {
    leaveCommentReview({
      variables: {
        where: { id: review.id },
        data: { shopComment: value },
      },
    });
  };

  return (
    <>
      <Button
        onClick={() => setVisible(true)}
        icon={review.shopComment ? <CheckOutlined /> : <PlusOutlined />}
        style={{
          borderColor: review.shopComment ? "green" : undefined,
          color: review.shopComment ? "green" : undefined,
        }}
        type="primary"
        ghost
        size="small"
      >
        Комментарий
      </Button>
      <Modal
        destroyOnClose
        title="Оставить комментарий"
        footer={[
          <Button key="close" onClick={() => setVisible(false)}>
            Закрыть
          </Button>,
          <Button
            loading={loading}
            key="submit"
            type="primary"
            onClick={handleLeaveComment}
          >
            Оставить
          </Button>,
        ]}
        open={visible}
        onCancel={() => setVisible(false)}
      >
        <Input.TextArea
          rows={5}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </Modal>
    </>
  );
};

const Rating = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .rating {
    margin-top: 2px;
    margin-right: 10px;
  }

  .ant-rate {
    font-size: 12px;
  }
`;

const Comment = styled.div`
  max-width: 300px;
`;

const Images = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Filters = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .filter {
    width: 200px;
    margin-bottom: 15px;
    margin-right: 15px;
  }

  .checkbox {
    margin-bottom: 15px;
    margin-right: 15px;
  }
`;
