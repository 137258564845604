import { gql } from "@apollo/client";

export const CATEGORY = gql`
  query CATEGORY($where: CategoryWhereInput!) {
    category(where: $where) {
      id
      createdAt
      updatedAt
      name
      icon
      blocked
      subcategories {
        id
        createdAt
        updatedAt
        name
        icon
        blocked
        order
      }
    }
  }
`;

export const CATEGORIES = gql`
  query CATEGORIES {
    categories {
      id
      createdAt
      updatedAt
      name
      icon
      blocked
      subcategories {
        id
        createdAt
        updatedAt
        name
        icon
        blocked
        order
      }
    }
  }
`;

export const CREATE_CATEGORY = gql`
  mutation CREATE_CATEGORY($data: CreateCategoryInput!) {
    createCategory(data: $data) {
      id
      createdAt
      updatedAt
      name
      icon
      blocked
      subcategories {
        id
        createdAt
        updatedAt
        name
        icon
        blocked
        order
      }
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation UPDATE_CATEGORY(
    $where: CategoryWhereInput!
    $data: UpdateCategoryInput!
  ) {
    updateCategory(where: $where, data: $data) {
      id
      createdAt
      updatedAt
      name
      icon
      blocked
      subcategories {
        id
        createdAt
        updatedAt
        name
        icon
        blocked
        order
      }
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation DELETE_CATEGORY($where: CategoryWhereInput!) {
    deleteCategory(where: $where) {
      id
      createdAt
      updatedAt
      name
      icon
      blocked
      subcategories {
        id
        createdAt
        updatedAt
        name
        icon
        blocked
        order
      }
    }
  }
`;

export const CREATE_SUBCATEGORY = gql`
  mutation CREATE_SUBCATEGORY($data: CreateSubcategoryInput!) {
    createSubcategory(data: $data) {
      id
      createdAt
      updatedAt
      name
      icon
      blocked
      order
    }
  }
`;

export const UPDATE_SUBCATEGORY = gql`
  mutation UPDATE_SUBCATEGORY(
    $where: SubcategoryWhereInput!
    $data: UpdateSubcategoryInput!
  ) {
    updateSubcategory(where: $where, data: $data) {
      id
      createdAt
      updatedAt
      name
      icon
      blocked
      order
    }
  }
`;

export const DELETE_SUBCATEGORY = gql`
  mutation DELETE_SUBCATEGORY($where: SubcategoryWhereInput!) {
    deleteSubcategory(where: $where) {
      id
      createdAt
      updatedAt
      name
      icon
      blocked
      order
    }
  }
`;
