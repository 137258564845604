import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Button, message, Popconfirm, Table } from "antd";
import { useNavigate } from "react-router-dom";

import Top from "../../components/Top";
import { useUser } from "../../utils";
import {
  DELETE_ADDRESS_POINT,
  SHOP_ADDRESS_POINTS,
} from "../../gqls/address-point";
import styled from "styled-components";

export const AddressPointsPage = () => {
  const { user } = useUser();

  const { data, loading, refetch } = useQuery(SHOP_ADDRESS_POINTS, {
    fetchPolicy: "network-only",
    variables: {
      where: { shopId: user?.shop?.id },
    },
  });

  const navigate = useNavigate();

  if (!user?.shop?.id) return null;

  const addressPoints = data?.shopAddressPoints || [];

  return (
    <>
      <Top
        title="Пункты выдачи"
        action={
          <Button onClick={() => navigate("/address-points/create")}>
            Добавить
          </Button>
        }
      />
      <Table
        loading={loading}
        dataSource={addressPoints}
        rowKey={(row) => row.id}
        columns={[
          {
            title: "Город",
            dataIndex: "city",
            key: "city",
          },
          {
            title: "Адрес",
            dataIndex: "address",
            key: "address",
          },
          {
            title: "Время работы",
            dataIndex: "workingTime",
            key: "workingTime",
          },
          {
            title: "",
            render: (row) => (
              <Actions>
                <Button
                  onClick={() => navigate(`/address-points/${row.id}/update`)}
                  type="primary"
                  ghost
                  size="small"
                >
                  Изменить
                </Button>
                <DeleteAddressPoint addressPoint={row} refetch={refetch} />
              </Actions>
            ),
          },
        ]}
      />
    </>
  );
};

const DeleteAddressPoint = ({ addressPoint, refetch }) => {
  const [deleteAddressPoint, { loading }] = useMutation(DELETE_ADDRESS_POINT, {
    onCompleted: () => {
      refetch();
    },
    onError: (err) => {
      console.error(err);
      message.error("Произошла ошибка, попробуйте еще раз");
    },
  });

  const handleConfirm = () => {
    deleteAddressPoint({ variables: { where: { id: addressPoint.id } } });
  };

  return (
    <Popconfirm title="Удалить?" onConfirm={handleConfirm}>
      <Button
        style={{ marginLeft: 10 }}
        loading={loading}
        danger
        ghost
        size="small"
      >
        Удалить
      </Button>
    </Popconfirm>
  );
};

const Actions = styled.div`
  display: flex;
  flex-direction: row;
`;
