import { Link } from "react-router-dom";
import { Menu } from "antd";
import {
  ShopOutlined,
  UserOutlined,
  GiftOutlined,
  OrderedListOutlined,
  ShoppingCartOutlined,
  EnvironmentOutlined,
  AimOutlined,
  BookOutlined,
  ContactsOutlined,
  QuestionOutlined,
  CodeOutlined,
  MessageOutlined,
  StockOutlined,
  FileAddOutlined,
} from "@ant-design/icons";

import { useUser } from "../utils";

const MenuComponent = () => {
  const { pathname } = window.location;

  const { user } = useUser();

  const moderatorMenuItems = [
    {
      icon: <ShopOutlined />,
      label: <Link to="/shops">Магазины</Link>,
      key: "/shops",
    },
    {
      icon: <GiftOutlined />,
      label: <Link to="/promotions">Акции</Link>,
      key: "/promotions",
    },
    {
      icon: <FileAddOutlined />,
      label: <Link to="/advertisings">Реклама</Link>,
      key: "/advertisings",
    },
    {
      icon: <MessageOutlined />,
      label: <Link to="/popup">Попап-окна</Link>,
      key: "/popup",
    },
    {
      icon: <OrderedListOutlined />,
      label: <Link to="/categories">Категории</Link>,
      key: "/categories",
    },
    {
      icon: <EnvironmentOutlined />,
      label: <Link to="/cities">Города</Link>,
      key: "/cities",
    },
    {
      icon: <OrderedListOutlined />,
      label: <Link to="/orders">Заказы</Link>,
      key: "/orders",
    },
    {
      icon: <ContactsOutlined />,
      label: <Link to="/contact-us">Обратная связь</Link>,
      key: "/contact-us",
    },
    {
      icon: <QuestionOutlined />,
      label: <Link to="/questions">Справки</Link>,
      key: "/questions",
    },
  ];

  const shopMenuItems = [
    {
      icon: <ShopOutlined />,
      label: <Link to="/shop">Магазин</Link>,
      key: "/shop",
    },
    {
      icon: <ShoppingCartOutlined />,
      label: <Link to="/shop-products">Товары</Link>,
      key: "/shop-products",
    },
    {
      icon: <CodeOutlined />,
      label: <Link to="/promocode">Промокоды</Link>,
      key: "/promocode",
    },
    {
      icon: <AimOutlined />,
      label: <Link to="/address-points">Пункты выдачи</Link>,
      key: "/address-points",
    },
    {
      icon: <StockOutlined />,
      label: <Link to="/metrics">Метрика</Link>,
      key: "/metrics",
    },
    {
      icon: <OrderedListOutlined />,
      label: <Link to="/orders">Заказы</Link>,
      key: "/orders",
    },
    {
      icon: <BookOutlined />,
      label: <Link to="/reviews">Отзывы</Link>,
      key: "/reviews",
    },
  ];

  return (
    <Menu
      theme="dark"
      mode="inline"
      selectedKeys={[pathname]}
      items={[
        {
          icon: <UserOutlined />,
          label: <Link to="/">Профиль</Link>,
          key: "/",
        },
        ...(user.role === "moderator" ? moderatorMenuItems : []),
        ...(user.role === "shop" ? shopMenuItems : []),
      ]}
    />
  );
};

export default MenuComponent;
