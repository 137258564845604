import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Input, Modal, Pagination, Select, Spin } from "antd";
import { useMutation, useQuery } from "@apollo/client";

import { SHOPS } from "../../gqls/shop";
import { PRODUCTS } from "../../gqls/product";
import { Link } from "react-router-dom";
import {
  ADD_PRODUCT_TO_PROMOTION,
  REMOVE_PRODUCT_FROM_PROMOTION,
} from "../../gqls/promotion";

const LIMIT = 24;

export const AddProductModal = ({
  visible,
  onClose,
  promotion,
  refetchProducts,
}) => {
  const [search, setSearch] = useState("");
  const [inputSearch, setInputSearch] = useState("");
  const [shopId, setShopId] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(1);

  const { data: shopsData } = useQuery(SHOPS);

  const { data, loading } = useQuery(PRODUCTS, {
    variables: {
      where: {
        search,
        shopId,
        skip: (currentPage - 1) * LIMIT,
        limit: LIMIT,
      },
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    let timeout;
    if (inputSearch) {
      timeout = setTimeout(() => {
        setCurrentPage(1);
        setSearch(inputSearch);
      }, 700);
    } else {
      if (timeout) clearTimeout(timeout);
      setCurrentPage(1);
      setSearch("");
    }
    return () => {
      if (timeout) clearInterval(timeout);
    };
  }, [inputSearch]);

  const isFilterEmpty = !search && !shopId;

  const shops = shopsData?.shops || [];
  const products = isFilterEmpty ? [] : data?.products?.products || [];
  const productsCount = data?.products?.count || 0;

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      title="Добавить товары в акцию"
      destroyOnClose
      footer={[
        <Button key="close" onClick={onClose}>
          Закрыть
        </Button>,
      ]}
      width="700px"
    >
      <Filter>
        <Input.Search
          placeholder="Поиск товара..."
          value={inputSearch}
          onChange={(e) => setInputSearch(e.target.value)}
          className="filter"
          allowClear
        />
        <Select
          value={shopId}
          onChange={(value) => {
            setCurrentPage(1);
            setShopId(value);
          }}
          placeholder="Магазин"
          className="filter"
          allowClear
        >
          {shops.map((item) => (
            <Select.Option key={item.id}>{item.name}</Select.Option>
          ))}
        </Select>
      </Filter>
      {loading ? (
        <Loading>
          <Spin />
        </Loading>
      ) : (
        <>
          {products.map((item) => (
            <ProductItem
              key={item.id}
              item={item}
              promotion={promotion}
              refetchProducts={refetchProducts}
            />
          ))}
          {products.length && productsCount > LIMIT ? (
            <Pagination
              current={currentPage}
              pageSize={LIMIT}
              total={productsCount}
              size="small"
              onChange={(page) => setCurrentPage(page)}
            />
          ) : null}
        </>
      )}
    </Modal>
  );
};

const ProductItem = ({ item, promotion, refetchProducts }) => {
  const [addProductToPromotion, { loading }] = useMutation(
    ADD_PRODUCT_TO_PROMOTION,
    {
      onCompleted: () => {
        refetchProducts();
      },
    }
  );
  const [removeProductFromPromotion, { loading: removing }] = useMutation(
    REMOVE_PRODUCT_FROM_PROMOTION,
    {
      onCompleted: () => {
        refetchProducts();
      },
    }
  );

  const handleAddToPromotion = () => {
    addProductToPromotion({
      variables: {
        where: { id: promotion.id },
        data: { productId: item.id },
      },
    });
  };

  const handleRemoveFromPromotion = () => {
    removeProductFromPromotion({
      variables: {
        where: { id: promotion.id },
        data: { productId: item.id },
      },
    });
  };

  const promotionProductsIds = promotion?.productsIds || [];

  const existInPromotion = promotionProductsIds.find(
    (productId) => productId === item.id
  );

  return (
    <Product>
      <div className="info">
        <Link className="image-link" to={`/shop-products/${item.id}`}>
          <img src={`/uploads/${item.images[0]}`} alt={item.images[0]} />
        </Link>
        <div>
          <div className="name">{item.name}</div>
          <div className="category">
            {item.category.name}
            {item.subcategory ? (
              <span className="subcategory">, {item.subcategory.name}</span>
            ) : null}
          </div>
          <Link className="link" to={`/shops/${item.shop.id}`}>
            {item.shop.name}
          </Link>
        </div>
      </div>
      {existInPromotion ? (
        <Button
          loading={removing}
          onClick={handleRemoveFromPromotion}
          danger
          ghost
          size="small"
        >
          Убрать
        </Button>
      ) : (
        <Button
          loading={loading}
          onClick={handleAddToPromotion}
          type="primary"
          ghost
          size="small"
        >
          Добавить
        </Button>
      )}
    </Product>
  );
};

const Filter = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  .filter {
    margin-bottom: 15px;
  }
`;

const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

const Product = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  .info {
    display: flex;
    flex-direction: row;
    align-items: center;

    .image-link {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 70px;
      height: 70px;
      object-fit: cover;
      margin-right: 10px;
    }

    .name {
      font-size: 14px;
    }

    .category {
      font-size: 12px;
      color: gray;
      margin-top: -3px;
    }

    .link {
      font-size: 14px;
    }
  }
`;
