import { gql } from "@apollo/client";

export const GET_ALL_POPUPS = gql`
  query GET_ALL_POPUPS {
    getAllPopups {
      id
      createdAt
      updatedAt
      title
      content
      image_url
      shown
    }
  }
`;

export const GET_POPUP = gql`
  query GET_POPUP($where: PopUpWhereInput!) {
    getPopup(where: $where) {
      id
      createdAt
      updatedAt
      title
      content
      image_url
      shown
    }
  }
`;

export const CREATE_POPUP = gql`
  mutation CreatePopup($data: PopupInput!) {
    createPopup(data: $data) {
      id
      createdAt
      updatedAt
      title
      content
      image_url
      shown
    }
  }
`;

export const UPDATE_POPUP = gql`
  mutation UpdatePopup($id: String!, $data: PopupInput!) {
    updatePopup(id: $id, data: $data) {
      id
      title
      content
      image_url
      shown
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_POPUP = gql`
  mutation DELETE_POPUP($id: String!) {
    deletePopup(id: $id) {
      id
      title
      content
      image_url
      shown
      createdAt
      updatedAt
    }
  }
`;
