import React from "react";
import { useQuery } from "@apollo/client";
import { Button, Table, Tag } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";

import Top from "../../components/Top";
import { SHOPS } from "../../gqls/shop";
import { useUser } from "../../utils";

export const ShopsPage = () => {
  const { user } = useUser();

  const { data, loading } = useQuery(SHOPS, {
    fetchPolicy: "network-only",
  });

  const shops = data?.shops;

  if (user?.role !== "moderator") return null;

  return (
    <>
      <Top
        title="Магазины"
        action={
          <Link to="/shops/create">
            <Button>Добавить</Button>
          </Link>
        }
      />
      <Table
        dataSource={shops}
        loading={loading}
        columns={[
          {
            title: "Название",
            dataIndex: "name",
            key: "name",
            render: (value, row) => (
              <Link to={`/shops/${row.id}`}>{value}</Link>
            ),
          },
          {
            title: "Статус",
            dataIndex: "blocked",
            key: "blocked",
            render: (value) =>
              value ? (
                <Tag color="red">Заблокирован</Tag>
              ) : (
                <Tag color="green">Работает</Tag>
              ),
          },
          {
            title: "Дата создания",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (value) => moment(value).format("DD.MM.YYYY HH:mm"),
          },
        ]}
        rowKey={(row) => row.id}
      />
    </>
  );
};
