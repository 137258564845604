import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Form, Input, message, Upload } from "antd";
import styled from "styled-components";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import Top from "../../components/Top";
import { useUser } from "../../utils";

import { SHOP_CATEGORY, UPDATE_SHOP_CATEGORY } from "../../gqls/shopCategory";

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e.length > 0 ? [e[e.length - 1]] : [];
  }
  return e?.fileList && e.fileList.length > 0
    ? [e.fileList[e.fileList.length - 1]]
    : [];
};

export const UpdateShopCategoryPage = () => {
  const { user } = useUser();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const shopId = searchParams.get("shopId");

  const navigate = useNavigate();
  const params = useParams();

  const [form] = Form.useForm();

  const { loading: shopCategoryLoading } = useQuery(SHOP_CATEGORY, {
    variables: { where: { id: params.id, shopId } },
    onCompleted: (data) => {
      if (data?.shopCategory) {
        form.setFieldsValue({
          name: data.shopCategory.name,
          image: [
            {
              status: "done",
              url: `/uploads/${data.shopCategory.image}`,
              name: data.shopCategory.image,
              response: { filename: data.shopCategory.image },
            },
          ],
        });
      }
    },
  });

  const [updateShopCategory, { loading }] = useMutation(UPDATE_SHOP_CATEGORY, {
    onCompleted: () => {
      message.success("Подкатегория успешно изменена");
      navigate("/shop");
    },
    onError: (err) => {
      console.error(err);
      message.error("Произошла ошибка, повторите еще раз");
    },
  });

  const handleFinish = (values) => {
    const image = values?.image?.[0]?.response?.filename;
    if (!image) {
      message.error("Выберите файл для загрузки");
      return;
    }
    updateShopCategory({
      variables: {
        data: {
          name: values.name,
          image,
        },
        where: { id: params.id, shopId },
      },
    });
  };

  return (
    <>
      <Top title="Изменить акцию" />
      <Container>
        <Form
          form={form}
          name="create-shop-category"
          onFinish={handleFinish}
          layout="vertical"
        >
          <Form.Item
            label="Название акции"
            name="name"
            rules={[{ required: true }]}
          >
            <Input disabled={shopCategoryLoading} />
          </Form.Item>
          <Form.Item
            name="image"
            label="Изображение"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[{ required: true }]}
          >
            <Upload
              name="file"
              action="/api/upload"
              listType="picture"
              accept="image/*"
              headers={{ authorization: localStorage.getItem("token") }}
              disabled={shopCategoryLoading}
            >
              <Button icon={<UploadOutlined />}>
                Нажмите, чтобы загрузить
              </Button>
            </Upload>
          </Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Изменить
          </Button>
        </Form>
      </Container>
    </>
  );
};

const Container = styled.div`
  max-width: 500px;
`;
