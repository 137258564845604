import React from "react";
import { useMutation } from "@apollo/client";
import { Button, Form, Input, message, Upload } from "antd";
import styled from "styled-components";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import Top from "../../components/Top";
import { useUser } from "../../utils";
import { CREATE_POPUP } from "../../gqls/popup";

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e.length > 0 ? [e[e.length - 1]] : [];
  }
  return e?.fileList && e.fileList.length > 0
    ? [e.fileList[e.fileList.length - 1]]
    : [];
};

export const CreatePopupPage = () => {
  const { user } = useUser();

  const navigate = useNavigate();

  const [createPopup, { loading }] = useMutation(CREATE_POPUP, {
    onCompleted: () => {
      message.success("Попап-окно успешно добавлена");
      navigate("/popup");
    },
    onError: (err) => {
      console.error(err);
      message.error("Произошла ошибка, повторите еще раз");
    },
  });

  const handleFinish = (values) => {
    const image = values?.image?.[0]?.response?.filename;
    if (!image) {
      message.error("Выберите файл для загрузки");
      return;
    }
    createPopup({
      variables: {
        data: {
          title: values.title,
          content: values.text,
          image_url: image,
          shown: false,
        },
      },
    });
  };

  if (user?.role !== "moderator") return null;

  return (
    <>
      <Top title="Добавить попап-окно" />
      <Container>
        <Form name="create-promotion" onFinish={handleFinish} layout="vertical">
          <Form.Item
            label="Название попап-окна"
            name="title"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Текст попап-окна"
            name="text"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="image"
            label="Изображение"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[{ required: true }]}
            extra="Размер баннера 1080px (ширина) на 600px (высота)"
          >
            <Upload
              name="file"
              action="/api/upload"
              listType="picture"
              accept="image/*"
              headers={{ authorization: localStorage.getItem("token") }}
            >
              <Button icon={<UploadOutlined />}>
                Нажмите, чтобы загрузить
              </Button>
            </Upload>
          </Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Добавить
          </Button>
        </Form>
      </Container>
    </>
  );
};

const Container = styled.div`
  max-width: 500px;
`;
