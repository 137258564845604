import Top from "../../components/Top";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import { Button, Form, Input, Checkbox, message } from "antd";
import { CREATE_PROMOCODE } from "../../gqls/promocode";
import { useUser } from "../../utils";
import { useNavigate } from "react-router-dom";

export const PromocodePage = () => {
  const [form] = Form.useForm();
  const { user } = useUser();

  const isShop = user?.role === "shop";
  const navigate = useNavigate();

  const [createPromocode, { loading }] = useMutation(CREATE_PROMOCODE, {
    onCompleted: () => {
      message.success("Промокод успешно добавлен");
      if (isShop) {
        navigate("/");
      }
    },
    onError: (err) => {
      console.error(err);
      message.error("Произошла ошибка, повторите еще раз");
    },
  });

  if (!user?.shop?.id) return null;

  const handleFinish = (values) => {
    createPromocode({
      variables: {
        data: {
          code: values.code,
          discount: parseFloat(values.discount),
          is_active: values.blocked,
          usage_limit: parseInt(values.usage_limit, 10),
          shopId: user?.id,
        },
      },
    });
  };

  return (
    <>
      <Top title="Создать промокод" />
      <Container>
        <Form
          form={form}
          onFinish={handleFinish}
          name="create-promocode"
          layout="vertical"
        >
          <Form.Item
            label="Название промокода"
            name="code"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Сколько рублей скидка"
            name="discount"
            rules={[{ required: true }]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item name="blocked" valuePropName="checked">
            <Checkbox>Не заблокирован</Checkbox>
          </Form.Item>
          <Form.Item
            label="Сколько раз можно использовать"
            name="usage_limit"
            rules={[{ required: true }]}
          >
            <Input type="number" />
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Применить
          </Button>
        </Form>
      </Container>
    </>
  );
};

const Container = styled.div`
  max-width: 500px;
`;
