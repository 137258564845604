import { gql } from "@apollo/client";

const PRODUCT_FRAGMENT = gql`
  fragment ProductReturn on Product {
    id
    createdAt
    updatedAt
    name
    images
    price
    fullPrice
    description
    blocked
    isTypeYakutia
    category {
      id
      name
    }
    subcategory {
      id
      name
    }
    shop {
      id
      name
      logo
      description
      phone
      email
    }
    colors
    sizes
  }
`;

export const PRODUCT = gql`
  ${PRODUCT_FRAGMENT}
  query PRODUCT($where: ProductWhereInput!) {
    product(where: $where) {
      ...ProductReturn
    }
  }
`;

export const PRODUCTS = gql`
  ${PRODUCT_FRAGMENT}
  query PRODUCTS($where: ProductsWhereInput) {
    products(where: $where) {
      products {
        ...ProductReturn
      }
      count
    }
  }
`;

export const CREATE_PRODUCT = gql`
  ${PRODUCT_FRAGMENT}
  mutation CREATE_PRODUCT($data: CreateProductInput!) {
    createProduct(data: $data) {
      ...ProductReturn
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  ${PRODUCT_FRAGMENT}
  mutation UPDATE_PRODUCT(
    $data: UpdateProductInput!
    $where: ProductWhereInput!
  ) {
    updateProduct(data: $data, where: $where) {
      ...ProductReturn
    }
  }
`;

export const DELETE_PRODUCT = gql`
  ${PRODUCT_FRAGMENT}
  mutation DELETE_PRODUCT($where: ProductWhereInput!) {
    deleteProduct(where: $where) {
      ...ProductReturn
    }
  }
`;

export const ORDER_PRODUCTS = gql`
  ${PRODUCT_FRAGMENT}
  query ORDER_PRODUCTS($where: OrderProductsWhereInput!) {
    orderProducts(where: $where) {
      ...ProductReturn
    }
  }
`;
