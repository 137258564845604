import React from "react";
import { useQuery } from "@apollo/client";
import { Button, List } from "antd";
import { Link } from "react-router-dom";

import Top from "../../components/Top";
import { useUser } from "../../utils";
import { QUESTION_TYPES } from "../../gqls/questions";

export const QuestionsPage = () => {
  const { user } = useUser();

  const { data, loading } = useQuery(QUESTION_TYPES, {
    fetchPolicy: "network-only",
  });

  if (user?.role !== "moderator") return null;

  const questions = data?.questions || [];

  return (
    <>
      <Top
        title="Справки"
        action={
          <Link to={`/questions/create`}>
            <Button>Добавить тему</Button>
          </Link>
        }
      />
      <List
        loading={loading}
        dataSource={questions}
        renderItem={(item) => (
          <List.Item key={item.id}>
            <Link to={`/questions/${item.id}`}>{item.name}</Link>
          </List.Item>
        )}
        bordered
      />
    </>
  );
};
