import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Empty, message, Popconfirm, Spin } from "antd";
import styled from "styled-components";
import moment from "moment";

import Top from "../../components/Top";
import LabeledValue from "../../components/LabeledValue";
import { useUser } from "../../utils";
import { DELETE_ADVERTISING, ADVERTISING } from "../../gqls/advertising";

export const AdvertisingPage = () => {
  const params = useParams();
  const { user } = useUser();
  const navigate = useNavigate();

  const { data, loading } = useQuery(ADVERTISING, {
    fetchPolicy: "network-only",
    variables: { where: { id: params.id } },
  });

  const [deleteAdvertising, { loading: deleting }] = useMutation(
    DELETE_ADVERTISING,
    {
      onCompleted: () => {
        message.success("Реклама удалена");
        navigate("/advertisings");
      },
      onError: (err) => {
        console.error(err);
        message.error("Произошла ошибка, повторите еще раз");
      },
    }
  );

  const advertising = data?.advertising;

  const handleDelete = () => {
    deleteAdvertising({
      variables: {
        where: { id: params.id },
      },
    });
  };

  if (!params.id) return null;
  if (user?.role !== "moderator") return null;

  return (
    <>
      <Top title="Реклама" />
      {loading ? (
        <Spin />
      ) : !advertising ? (
        <Empty description="Не найдено" />
      ) : (
        advertising && (
          <>
            {advertising.image && (
              <Logo
                src={`/uploads/${advertising.image}`}
                alt={advertising.image}
              />
            )}
            <LabeledValue label="Название" value={advertising.name} />
            <LabeledValue
              label="Дата создания"
              value={moment(advertising.createdAt).format("DD.MM.YYYY HH:mm")}
            />
            <View>
              <Link to={`/advertisings/${params.id}/update`}>
                <Button type="primary" ghost>
                  Изменить
                </Button>
              </Link>
              <Popconfirm title="Удалить?" onConfirm={handleDelete}>
                <Button
                  loading={deleting}
                  style={{ marginLeft: 10 }}
                  type="primary"
                  ghost
                  danger
                >
                  Удалить
                </Button>
              </Popconfirm>
            </View>
          </>
        )
      )}
    </>
  );
};

const Logo = styled.img`
  height: 150px;
  border: 1px solid #d8d8d8;
  margin-bottom: 15px;
`;

const View = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
