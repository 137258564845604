import { gql } from "@apollo/client";

const QUESTION_FRAGMENT = gql`
  fragment QuestionReturn on Question {
    id
    title
    answer
  }
`;

const QUESTION_TYPE_FRAGMENT = gql`
  ${QUESTION_FRAGMENT}
  fragment QuestionTypeReturn on QuestionType {
    id
    name
    questions {
      ...QuestionReturn
    }
  }
`;

export const QUESTION_TYPES = gql`
  ${QUESTION_TYPE_FRAGMENT}
  query QUESTION_TYPES {
    questions {
      ...QuestionTypeReturn
    }
  }
`;

export const CREATE_QUESTION_TYPE = gql`
  ${QUESTION_TYPE_FRAGMENT}
  mutation CREATE_QUESTION_TYPE($data: CreateQuestionTypeInput!) {
    createQuestionType(data: $data) {
      ...QuestionTypeReturn
    }
  }
`;

export const UPDATE_QUESTION_TYPE = gql`
  ${QUESTION_TYPE_FRAGMENT}
  mutation UPDATE_QUESTION_TYPE(
    $where: QuestionTypeWhereInput!
    $data: UpdateQuestionTypeInput
  ) {
    updateQuestionType(where: $where, data: $data) {
      ...QuestionTypeReturn
    }
  }
`;

export const DELETE_QUESTION_TYPE = gql`
  ${QUESTION_TYPE_FRAGMENT}
  mutation DELETE_QUESTION_TYPE($where: QuestionTypeWhereInput!) {
    deleteQuestionType(where: $where) {
      ...QuestionTypeReturn
    }
  }
`;

export const CREATE_QUESTION = gql`
  ${QUESTION_FRAGMENT}
  mutation CREATE_QUESTION($data: CreateQuestionInput!) {
    createQuestion(data: $data) {
      ...QuestionReturn
    }
  }
`;

export const UPDATE_QUESTION = gql`
  ${QUESTION_FRAGMENT}
  mutation UPDATE_QUESTION(
    $where: QuestionWhereInput!
    $data: UpdateQuestionInput
  ) {
    updateQuestion(where: $where, data: $data) {
      ...QuestionReturn
    }
  }
`;

export const DELETE_QUESTION = gql`
  ${QUESTION_FRAGMENT}
  mutation DELETE_QUESTION($where: QuestionWhereInput!) {
    deleteQuestion(where: $where) {
      ...QuestionReturn
    }
  }
`;
