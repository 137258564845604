import { gql } from "@apollo/client";

const SHOP_FRAGMENT = gql`
  fragment ShopReturn on Shop {
    id
    name
    logo
    description
    phone
    email
    blocked
    createdAt
    updatedAt
    deliveryExists
    deliveryDeadline
    deliveryMinAmount
    priority
  }
`;

export const SHOPS = gql`
  ${SHOP_FRAGMENT}
  query SHOPS($data: ShopsInput) {
    shops(data: $data) {
      ...ShopReturn
    }
  }
`;

export const SHOP = gql`
  ${SHOP_FRAGMENT}
  query SHOP($where: ShopWhereInput!) {
    shop(where: $where) {
      ...ShopReturn
    }
  }
`;

export const CREATE_SHOP = gql`
  ${SHOP_FRAGMENT}
  mutation CREATE_SHOP($data: CreateShopInput!) {
    createShop(data: $data) {
      ...ShopReturn
    }
  }
`;

export const UPDATE_SHOP = gql`
  ${SHOP_FRAGMENT}
  mutation UPDATE_SHOP($where: UpdateShopWhereInput!, $data: UpdateShopInput!) {
    updateShop(where: $where, data: $data) {
      ...ShopReturn
    }
  }
`;
