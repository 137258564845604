import React, { useState } from "react";
import {
  Button,
  Form as AntForm,
  Input,
  InputNumber,
  message,
  Select,
} from "antd";
import styled from "styled-components";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import Top from "../../components/Top";
import { colors, sizes, useUser } from "../../utils";
import { CATEGORIES } from "../../gqls/category";
import { CREATE_PRODUCT } from "../../gqls/product";
import { Upload } from "../../components/Upload";

export const CreateShopProductPage = () => {
  const { user } = useUser();

  const navigate = useNavigate();

  const [files, setFiles] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);

  const { data: categoryData } = useQuery(CATEGORIES);

  const [createProduct, { loading }] = useMutation(CREATE_PRODUCT, {
    onCompleted: () => {
      message.success("Товар успешно добавлен");
      navigate("/shop-products");
    },
    onError: (err) => {
      console.error(err);
      message.error("Произошла ошибка, повторите еще раз");
    },
  });

  const [form] = Form.useForm();

  const handleFinish = (values) => {
    const images = files
      .map((item) => item?.response?.filename)
      .filter((item) => item);
    if (!images.length) {
      message.error("Загрузите хотя бы одно изображение");
      return;
    }
    const existUploading = files.filter((item) => item.status === "uploading");
    if (existUploading.length) {
      message.error("Дождитель загрузки изображений");
      return;
    }
    const existError = files.filter((item) => item.status === "error");
    if (existError.length) {
      message.error("Удалите изображения с ошибкой");
      return;
    }
    createProduct({
      variables: {
        data: {
          name: values.name,
          images,
          price: values.price,
          salePrice: values.salePrice,
          description: values.description,
          categoryId: values.categoryId,
          subcategoryId: values.subcategoryId ?? undefined,
          colors: selectedColors.length ? selectedColors : undefined,
          sizes: selectedSizes.length ? selectedSizes : undefined,
        },
      },
    });
  };

  if (!user?.shop?.id) return null;

  const categories = categoryData?.categories ?? [];

  return (
    <>
      <Top title="Добавить товар" />
      <Form
        onFinish={handleFinish}
        form={form}
        name="create-shop-product"
        layout="vertical"
      >
        <Form.Item
          name="name"
          label="Название товара"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Описание"
          rules={[{ required: true }]}
        >
          <Input.TextArea rows={6} />
        </Form.Item>
        <Form.Item name="price" label="Цена" rules={[{ required: true }]}>
          <InputNumber style={{ width: "100%" }} addonBefore="₽" />
        </Form.Item>
        <Form.Item name="salePrice" label="Цена со скидкой">
          <InputNumber style={{ width: "100%" }} addonBefore="₽" />
        </Form.Item>
        <Form.Item
          name="categoryId"
          label="Категория"
          rules={[{ required: true }]}
        >
          <Select onChange={() => form.setFieldValue("subcategoryId", null)}>
            {categories.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.categoryId !== currentValues.categoryId
          }
        >
          {({ getFieldValue }) => {
            const categoryId = getFieldValue("categoryId");
            const category = categories.find((item) => item.id === categoryId);
            const subcategories = category?.subcategories || [];
            return (
              <Form.Item name="subcategoryId" label="Подкатегория">
                <Select>
                  {subcategories.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item label="Изображения" required>
          <Upload multiple fileList={files} setFileList={setFiles} />
        </Form.Item>
        <Form.Item label="Цвета">
          <div className="offers">
            {colors.sort().map((color) => (
              <div
                key={color}
                className={`offer${
                  selectedColors.includes(color) ? " active-offer" : ""
                }`}
                style={{ backgroundColor: color }}
                onClick={() => {
                  setSelectedColors((prev) => {
                    if (prev.includes(color)) {
                      return prev.filter((item) => item !== color);
                    }
                    return [...prev, color];
                  });
                }}
              />
            ))}
          </div>
        </Form.Item>
        <Form.Item label="Размеры">
          <div className="sizes">
            <div className="offers">
              {sizes.sort().map((size) => (
                <div
                  key={size}
                  className={`offer${
                    selectedSizes.includes(size) ? " active-offer" : ""
                  }`}
                  onClick={() => {
                    setSelectedSizes((prev) => {
                      if (prev.includes(size)) {
                        return prev.filter((item) => item !== size);
                      }
                      return [...prev, size];
                    });
                  }}
                >
                  {size}
                </div>
              ))}
            </div>
          </div>
        </Form.Item>
        <Button loading={loading} type="primary" htmlType="submit">
          Добавить
        </Button>
      </Form>
    </>
  );
};

const Form = styled(AntForm)`
  max-width: 500px;

  .offers {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .offer {
      width: 30px;
      height: 30px;
      border: 1px solid silver;
      margin: 5px;
      cursor: pointer;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .active-offer {
      border: 3px solid red;
    }
  }
`;
