import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Input, Select, Table, Tag } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import IMask from "imask";

import Top from "../../components/Top";
import { ORDERS } from "../../gqls/order";
import { useUser } from "../../utils";

const moneyMask = IMask.createMask({
  mask: Number,
  thousandsSeparator: " ",
});

export const OrdersPage = () => {
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const [filterStatus, setFilterStatus] = useState(undefined);
  const [filterDeliveryType, setFilterDeliveryType] = useState(undefined);
  const [filterNumber, setFilterNumber] = useState(undefined);

  const { user } = useUser();

  const isModerator = user?.role === "moderator";

  const { data, loading } = useQuery(ORDERS, {
    pollInterval: 5000,
    fetchPolicy: "network-only",
    variables: {
      where: {
        skip,
        limit,
        status: filterStatus,
        deliveryType: filterDeliveryType,
        number: filterNumber,
      },
    },
  });

  const handleTableChange = (pagination) => {
    if (pagination.pageSize) {
      setLimit(pagination.pageSize);
    }
    setSkip((pagination.current - 1) * limit);
  };

  const orders = data?.orders?.orders || [];
  const count = data?.orders?.count || 0;

  const renderStatus = (status) => {
    if (status === "new") {
      return <Tag color="default">В обработке</Tag>;
    }
    if (status === "on-pickup") {
      return <Tag color="blue">Готов к выдаче</Tag>;
    }
    if (status === "on-delivery") {
      return <Tag color="blue">Ожидается доставка</Tag>;
    }
    if (status === "done") {
      return <Tag color="green">Выполнен</Tag>;
    }
    if (status === "cancelled") {
      return <Tag color="red">Отменен</Tag>;
    }
    return null;
  };

  return (
    <Container>
      <Top title="Заказы" />
      <Filters>
        <Input.Search
          className="filter"
          placeholder="Поиск по номеру"
          onSearch={(value) => setFilterNumber(value)}
          allowClear
        />
        <Select
          className="filter"
          placeholder="Статус"
          allowClear
          value={filterStatus}
          onChange={(value) => setFilterStatus(value)}
        >
          <Select.Option value="new">В обработке</Select.Option>
          <Select.Option value="on-pickup">Готов к выдаче</Select.Option>
          <Select.Option value="on-delivery">Ожидается доставка</Select.Option>
          <Select.Option value="done">Выполнен</Select.Option>
          <Select.Option value="cancelled">Отменен</Select.Option>
        </Select>
        <Select
          className="filter"
          placeholder="Тип доставки"
          allowClear
          value={filterDeliveryType}
          onChange={(value) => setFilterDeliveryType(value)}
        >
          <Select.Option value="delivery">Доставка</Select.Option>
          <Select.Option value="pick-up">Самовывоз</Select.Option>
        </Select>
      </Filters>
      <Table
        pagination={{
          pageSize: limit,
          total: count,
          showSizeChanger: true,
        }}
        scroll={{ x: 1100 }}
        onChange={handleTableChange}
        rowKey={(row) => row.id}
        loading={loading}
        dataSource={orders}
        rowClassName={(row) => (row.status === "new" ? "new-order" : undefined)}
        columns={[
          {
            title: "Номер",
            dataIndex: "number",
            key: "number",
            render: (number, order) => (
              <Link to={`/orders/${order.id}`}>{number}</Link>
            ),
          },
          isModerator
            ? {
                title: "Магазин",
                dataIndex: "shop",
                key: "shop",
                render: (shop) => (
                  <Shop>
                    <img
                      className="logo"
                      src={`/uploads/${shop.logo}`}
                      alt={shop.logo}
                    />
                    <div className="name">{shop.name}</div>
                  </Shop>
                ),
              }
            : undefined,
          {
            title: "Товаров",
            render: (order) => order.products.length,
          },
          {
            title: "Пользователь",
            render: (order) => order.user.phone,
          },
          {
            title: "Тип доставки",
            render: (order) =>
              order.deliveryType === "delivery" ? "Доставка" : "Самовывоз",
          },
          {
            title: "Сумма",
            render: (order) =>
              moneyMask.resolve(String(order.totalPrice)) + " ₽",
          },
          {
            title: "Статус",
            dataIndex: "status",
            key: "status",
            render: (status) => renderStatus(status),
          },
          {
            title: "Дата создания",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (createdAt) => moment(createdAt).format("DD.MM.YYYY HH:mm"),
          },
        ].filter((item) => !!item)}
      />
    </Container>
  );
};

const Container = styled.div`
  .new-order {
  }
`;

const Shop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .logo {
    width: 40px;
    height: 40px;
    object-fit: cover;
    background-color: white;
    border-radius: 20px;
  }

  .name {
    margin-left: 10px;
  }
`;

const Filters = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .filter {
    width: 200px;
    margin-bottom: 15px;
    margin-right: 15px;
  }
`;
