import { gql } from "@apollo/client";

export const PROMOTIONS = gql`
  query PROMOTIONS($data: PromotionsInput) {
    promotions(data: $data) {
      id
      createdAt
      updatedAt
      name
      image
      productsIds
    }
  }
`;

export const PROMOTION = gql`
  query PROMOTION($where: PromotionWhereInput!) {
    promotion(where: $where) {
      id
      createdAt
      updatedAt
      name
      image
      productsIds
    }
  }
`;

export const CREATE_PROMOTION = gql`
  mutation CREATE_PROMOTION($data: CreatePromotionInput!) {
    createPromotion(data: $data) {
      id
      createdAt
      updatedAt
      name
      image
      productsIds
    }
  }
`;

export const UPDATE_PROMOTION = gql`
  mutation UPDATE_PROMOTION(
    $data: UpdatePromotionInput!
    $where: PromotionWhereInput!
  ) {
    updatePromotion(data: $data, where: $where) {
      id
      createdAt
      updatedAt
      name
      image
      productsIds
    }
  }
`;

export const DELETE_PROMOTION = gql`
  mutation DELETE_PROMOTION($where: PromotionWhereInput!) {
    deletePromotion(where: $where) {
      id
      createdAt
      updatedAt
      name
      image
      productsIds
    }
  }
`;

export const ADD_PRODUCT_TO_PROMOTION = gql`
  mutation ADD_PRODUCT_TO_PROMOTION(
    $where: PromotionWhereInput!
    $data: ProductToPromotionInput!
  ) {
    addProductToPromotion(where: $where, data: $data) {
      id
      createdAt
      updatedAt
      name
      image
      productsIds
    }
  }
`;

export const REMOVE_PRODUCT_FROM_PROMOTION = gql`
  mutation REMOVE_PRODUCT_FROM_PROMOTION(
    $where: PromotionWhereInput!
    $data: ProductToPromotionInput!
  ) {
    removeProductFromPromotion(where: $where, data: $data) {
      id
      createdAt
      updatedAt
      name
      image
      productsIds
    }
  }
`;
