import React, { useState } from "react";
import { Button, Input, Select, Table, Tag } from "antd";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import moment from "moment";

import Top from "../../components/Top";
import { useUser } from "../../utils";
import { Link } from "react-router-dom";
import { PRODUCTS } from "../../gqls/product";
import { CATEGORIES } from "../../gqls/category";

export const ShopProductsPage = () => {
  const { user } = useUser();

  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);

  const [filterSearch, setFilterSearch] = useState(undefined);
  const [filterCategory, setFilterCategory] = useState(undefined);
  const [filterSubcategory, setFilterSubcategory] = useState(undefined);

  const { data: categoriesData } = useQuery(CATEGORIES);

  const { data, loading } = useQuery(PRODUCTS, {
    variables: {
      where: {
        skip,
        limit,
        shopId: user.shop.id,
        categoryId: filterCategory,
        subcategoryId: filterSubcategory,
        search: filterSearch,
      },
    },
    fetchPolicy: "network-only",
  });

  const handleTableChange = (pagination) => {
    if (pagination.pageSize) {
      setLimit(pagination.pageSize);
    }
    setSkip((pagination.current - 1) * limit);
  };

  const products = data?.products?.products || [];
  const categories = categoriesData?.categories || [];

  const selectedCategory = filterCategory
    ? categories.find((item) => item.id === filterCategory)
    : undefined;

  if (!user?.shop?.id) return null;

  return (
    <>
      <Top
        title="Товары"
        action={
          <Row>
            <Link to="/shop-products/create">
              <Button>Добавить</Button>
            </Link>
            <Link to="/shop-products/excel">
              <Button className="item">Загрузить Excel</Button>
            </Link>
          </Row>
        }
      />
      <Filters>
        <Input.Search
          onSearch={(value) => {
            setFilterSearch(value);
          }}
          allowClear
          className="filter"
          placeholder="Поиск"
        />
        <Select
          value={filterCategory}
          onChange={(value) => {
            setFilterSubcategory(undefined);
            setFilterCategory(value);
          }}
          className="filter"
          placeholder="Категория"
          allowClear
        >
          {categories.map((item) => (
            <Select.Option key={item.id} value={item.id}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
        {selectedCategory && selectedCategory.subcategories.length ? (
          <Select
            value={filterSubcategory}
            onChange={(value) => setFilterSubcategory(value)}
            className="filter"
            placeholder="Подкатегория"
            allowClear
          >
            {selectedCategory.subcategories.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        ) : null}
      </Filters>
      <Table
        pagination={{
          pageSize: limit,
          total: data?.products?.count || 0,
          showSizeChanger: true,
        }}
        onChange={handleTableChange}
        dataSource={products}
        loading={loading}
        rowKey={(row) => row.id}
        scroll={{ x: 1000 }}
        columns={[
          {
            title: "Название",
            dataIndex: "name",
            key: "name",
            render: (name, row) => (
              <Link to={`/shop-products/${row.id}`}>{name}</Link>
            ),
          },
          {
            title: "Цена",
            render: (row) => (
              <div>
                {row.price} ₽
                {row.fullPrice ? (
                  <FullPrice>{row.fullPrice} ₽</FullPrice>
                ) : null}
              </div>
            ),
          },
          {
            title: "Категория",
            render: (row) => (
              <Category>
                <div className="category-item">
                  {row.category.name}
                  {row.subcategory ? ", " + row.subcategory.name : ""}
                </div>
                {row.isTypeYakutia ? <Tag color="cyan">Yakutia</Tag> : null}
              </Category>
            ),
          },
          {
            title: "Статус",
            render: (row) =>
              row.blocked ? (
                <Tag color="red">Заблокирован</Tag>
              ) : (
                <Tag color="green">Доступен</Tag>
              ),
          },
          {
            title: "Дата создания",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (createdAt) => moment(createdAt).format("DD.MM.YYYY HH:mm"),
          },
        ]}
      />
    </>
  );
};

const Filters = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .filter {
    width: 200px;
    margin-bottom: 15px;
    margin-right: 15px;
  }
`;

const FullPrice = styled.span`
  text-decoration: line-through;
  margin-left: 5px;
  font-size: 12px;
  color: gray;
`;

const Category = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .category-item {
    margin-right: 8px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .item {
    margin-left: 10px;
  }

  @media screen and (max-width: 400px) {
    flex-direction: column;
    align-items: flex-end;

    .item {
      margin-left: 0;
      margin-top: 10px;
    }
  }
`;
