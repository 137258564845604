import React from "react";
import { Button, Form, Input, message, Select } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import Top from "../../components/Top";
import { useUser } from "../../utils";
import { CREATE_ADDRESS_POINT } from "../../gqls/address-point";
import { CITIES } from "../../gqls/city";

export const CreateAddressPointPage = () => {
  const { user } = useUser();

  const navigate = useNavigate();

  const { data: citiesData } = useQuery(CITIES);

  const [createAddressPoint, { loading }] = useMutation(CREATE_ADDRESS_POINT, {
    onCompleted: () => {
      message.success("Пункт выдачи добавлен");
      navigate("/address-points");
    },
    onError: (err) => {
      console.error(err);
      message.error("Произошла ошибка, попробуйте еще раз");
    },
  });

  const handleFinish = (values) => {
    createAddressPoint({ variables: { data: values } });
  };

  if (!user?.shop?.id) return null;

  const cities = citiesData?.cities || [];

  return (
    <>
      <Top title="Добавить пункт выдачи" />
      <Form
        layout="vertical"
        name="create-address-point"
        style={{ maxWidth: 500 }}
        onFinish={handleFinish}
      >
        <Form.Item name="city" label="Город" rules={[{ required: true }]}>
          <Select>
            {cities.map((item) => (
              <Select.Option key={item.id} value={item.name}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="address" label="Адрес" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="workingTime" label="Рабочее время">
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Описание">
          <Input.TextArea rows={4} />
        </Form.Item>
        <Button loading={loading} type="primary" htmlType="submit">
          Добавить
        </Button>
      </Form>
    </>
  );
};
