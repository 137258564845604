import React from "react";
import { useQuery } from "@apollo/client";
import { GET_ALL_POPUPS } from "../../gqls/popup";
import { Button, Empty, Spin } from "antd";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";

import Top from "../../components/Top";

export const PopupsPage = () => {
  const { data, loading } = useQuery(GET_ALL_POPUPS, {
    fetchPolicy: "network-only",
  });

  const navigate = useNavigate();
  const popups = data?.getAllPopups || [];

  return (
    <>
      <Top
        title="Попап-Окна"
        action={
          <Link to="/popup/create">
            <Button>Добавить</Button>
          </Link>
        }
      />
      {loading ? (
        <Loading>
          <Spin />
        </Loading>
      ) : null}
      {!loading && popups.length === 0 ? (
        <Empty description="Нет попап-окон" />
      ) : null}
      {popups.length ? (
        <PopupContainer>
          {popups.map((item) => (
            <PopupContent
              onClick={() => navigate(`/popup/${item.id}`)}
              key={item.id}
            >
              <img src={`/uploads/${item.image_url}`} alt={item.image_url} />
              <div className="text">{item.title}</div>
            </PopupContent>
          ))}
        </PopupContainer>
      ) : null}
    </>
  );
};

const Loading = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
`;

const PopupContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const PopupContent = styled.div`
  margin-right: 15px;
  margin-bottom: 15px;

  img {
    width: 200px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
    cursor: pointer;
    transition: 0.3s all;

    &:hover {
      opacity: 0.8;
    }
  }

  .text {
    text-align: center;
    margin-top: 5px;
  }
`;
