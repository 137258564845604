import React, { useState } from "react";
import { Button, Empty, Popconfirm, Spin, Table, Tag } from "antd";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import styled from "styled-components";

import Top from "../../components/Top";
import { CATEGORY, UPDATE_CATEGORY } from "../../gqls/category";
import LabeledValue from "../../components/LabeledValue";
import { CreateSubcategoryModal } from "./create-subcategory";
import { UpdateSubcategoryModal } from "./update-subcategory";

export const CategoryPage = () => {
  const params = useParams();

  const [createSubcategoryVisible, setCreateSubcategoryVisible] =
    useState(false);

  const { data, loading, refetch } = useQuery(CATEGORY, {
    fetchPolicy: "network-only",
    variables: { where: { id: params.id } },
  });

  const [updateCategory, { loading: updating }] = useMutation(UPDATE_CATEGORY);

  const category = data?.category;

  const updateBlock = (blocked) => {
    updateCategory({
      variables: {
        where: { id: params.id },
        data: { blocked },
      },
    });
  };

  return (
    <>
      <Top title="Категория" />
      {loading ? (
        <Loading>
          <Spin />
        </Loading>
      ) : !category ? (
        <Empty description="Не найдено" />
      ) : (
        <>
          <LabeledValue label="Название" value={category.name} />
          <LabeledValue
            label="Статус"
            value={
              category.blocked ? (
                <Tag color="red">Заблокирована</Tag>
              ) : (
                <Tag color="green">Доступна</Tag>
              )
            }
          />
          {category.blocked ? (
            <Popconfirm
              title="Разблокировать?"
              onConfirm={() => updateBlock(false)}
            >
              <Button loading={updating} type="primary" ghost>
                Разблокировать
              </Button>
            </Popconfirm>
          ) : (
            <Popconfirm
              title="Заблокировать?"
              onConfirm={() => updateBlock(true)}
            >
              <Button loading={updating} type="primary" danger ghost>
                Заблокировать
              </Button>
            </Popconfirm>
          )}
          <Top
            title="Подкатегории"
            style={{ marginTop: 15 }}
            action={
              <Button onClick={() => setCreateSubcategoryVisible(true)}>
                Добавить подкатегорию
              </Button>
            }
          />
          <Table
            dataSource={category.subcategories}
            columns={[
              {
                title: "Название",
                dataIndex: "name",
                key: "name",
              },
              {
                title: "Статус",
                dataIndex: "blocked",
                key: "blocked",
                render: (value) =>
                  value ? (
                    <Tag color="red">Заблокирована</Tag>
                  ) : (
                    <Tag color="green">Доступна</Tag>
                  ),
              },
              {
                title: "Сортировка",
                dataIndex: "order",
                key: "order",
              },
              {
                title: "#",
                render: (row) => (
                  <SubcategoryAction
                    subcategory={row}
                    refetchCategories={refetch}
                  />
                ),
              },
            ]}
            rowKey={(row) => row.id}
          />
          <CreateSubcategoryModal
            visible={createSubcategoryVisible}
            onClose={() => setCreateSubcategoryVisible(false)}
            refetchCategories={refetch}
            categoryId={params.id}
          />
        </>
      )}
    </>
  );
};

const SubcategoryAction = ({ subcategory, refetchCategories }) => {
  const [updateVisible, setUpdateVisible] = useState(false);
  return (
    <>
      <Button
        onClick={() => setUpdateVisible(true)}
        type="primary"
        ghost
        size="small"
      >
        Изменить
      </Button>
      <UpdateSubcategoryModal
        visible={updateVisible}
        onClose={() => setUpdateVisible(false)}
        refetchCategories={refetchCategories}
        subcategory={subcategory}
      />
    </>
  );
};

const Loading = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
`;
